.calendarWrapper {
  width: 100%;
  
  button {
    background: none;
    color: inherit;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    font-size: 0.8em;
    font-weight: 700;
  }
}

.calendarHeader {
  border-bottom: 1px solid #e9e9e9;
  .thickText {
    font-weight: 700;
  }
  .thinText {
    font-weight: 200;
  }
}

.calendarHeaderButtons {
  overflow: auto;
  color: #ff3e52;
  margin: 20px 0;
}
.calendarHeaderButtons button:nth-of-type(1) {
  float: left;
}
.calendarHeaderButtons button:nth-of-type(2) {
  float: right;
}

.modeWrapper {
  text-align: center;
}

.modeButton {
  padding: 10px 20px;
  color: #202029;
  transition-duration: 0.3s;
}

.modeButton:hover {
  color: #ff3e52;
}

.modeButtonActive {
  color: #ff3e52;
}
