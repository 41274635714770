.wrapper {
    position: relative;
    width: fit-content;
    margin-top: .5rem;
}

.currentAnimation {
    width: 150px;
    height: 200px;
    border: 1px solid rgb(202, 200, 200);
    box-shadow: 0 0 .5rem rgb(224, 222, 222);
    border-radius: .2rem;
    cursor: pointer;
}

.popup {
    position: absolute;
    top: 1rem;
    left: calc(100% + 1rem);
    border: 1px solid rgb(224, 222, 222);
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    width: 322px;
    z-index: 1;
    border-radius: .5rem;
    padding: .5rem;
}

.empty {
    width: 152px;
    height: 202px;
    border: 1px solid rgb(224, 222, 222);
    border-radius: .2rem;
}

.animationContainer {
    width: 150px;
    height: 200px;
}