.fileBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin: 10px;
    transition: all 0.2s ease-in;
    position: relative;
    width: fit-content;
}

.fileIcon {
    font-size: 60px;
    color: rgb(197, 195, 195);
    margin-bottom: 5px;
}

.fileBlock img {
    max-width: 100%;
    max-height: 100%;
}

.deleteIcon {
    font-size: 18px;
    position: absolute;
    top: 0;
    right: 0;
    color: red;
    display: none;
}

.deleteIcon:hover {
    transform: scale(1.3);
}

.fileBlock div {
    width: 70px;
    height: 59px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fileBlock span {
    max-width: 80px;
    word-break: break-all;
    transition: all 0.2s ease-in;
    user-select: none;
    font-size: 13px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fileBlock:hover {
    opacity: 0.7;
}

.fileBlock:hover span {
    text-decoration: underline;
    color: red;
}

.fileBlock:hover .deleteIcon{
    display: block;
}