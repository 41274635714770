.load_wrapper {
    position: absolute;
    /* top: 50%;
    left: 50%; */
    z-index: 9;
}

.lds_ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds_ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid rgba(146, 146, 146, 0.979);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: rgb(146, 146, 146) transparent transparent transparent;
}

.lds_ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds_ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds_ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}