.wrapper {
    padding: 0 5rem;
    width: 100%;
}

.wrapper > h1 {
    text-align: center;
    margin: 2rem 0;
    color: rgb(175, 18, 18);
}

.rolesList {
    list-style: none;
}

.firstLiBlock {
    display: flex;
}

.firstLiBlock > div:first-child {
    font-weight: 600;
    font-size: 17px;
    color: #274684;
    width: 300px;
}

.firstLiBlock > div:last-child {
    color: #778AAF;
    margin-left: 1rem;
}

.secondLiBlock {
    display: flex;
    align-items: center;
}

.secondLiBlock > div {
}

.secondLiBlock > svg {
    color: #314561;
    margin: 0 .5rem .25rem 2rem;
}

.secondLiBlock input {
    margin-right: .5rem;
}

.wrapper > button {
    margin-top: 2rem;
    margin-left: 1rem;
}

.modal {
    max-width: 750px;
    width: 100%;
    height: auto;
    padding: 0;
    background-color: #fff;
    overflow: hidden;
}

.modalTitle {
    width: 100%;
    height: 34px;
    display: flex;
    align-items: center;
    background: linear-gradient(45deg, rgb(185 26 26) 0%, rgb(253 196 126) 100%);
    padding: 0 16px;
    color: #ffffff;
    font-size: 23px;
    font-weight: 400;
}

.modalBody {
    width: 100%;
    padding: 15px 15px 30px;
}