.wrapper {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.filtersBlock, .filtersBlock > div {
    width: 100%;
}

.filtersBlock > div {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
}

.filtersBlock > div:last-child {
    margin-top: .5rem;
}

.filtersBlock > div:last-child > div + div {
    margin-left: 2rem;
}

.titleBlock {
    margin: 10px 0;
}

.tableBlock {
    max-width: 80%;
    overflow: auto;
    position: relative;
}

.tableBlock table {
    border-collapse: collapse;
    font-size: 15px;
}

.tableBlock thead th {
    cursor: pointer;
}

.tableBlock thead th:nth-child(2) {
    width: 350px;
}

.tableBlock thead th:nth-child(4) {
    width: 200px;
}

.tableBlock thead th:nth-child(5) {
    width: 400px;
}

.tableBlock tbody tr:hover {
    background-color: rgba(128, 128, 128, 0.582);
}

.tableBlock tbody tr th:nth-child(2):hover {
    cursor: pointer;
    text-decoration: underline;
}

.tableBlock td, .tableBlock th {
    border: 1px solid rgb(80, 79, 79);
    padding: 3px;
    color: rgb(80, 79, 79);
}

.tableBlock th {
    background-color: rgba(240, 248, 255, 0.693);
}

.tableBlock td {
    text-align: center;
    background-color: rgba(162, 250, 221, 0.596);
}

.titleBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgb(80, 79, 79);
}

.buttomPDF {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fa0c00;
    border-radius: 3px;
    padding: .5rem 1rem;
    color: #fff; 
    cursor: pointer;
    margin: 1rem auto 0 0;
}

.selectBlock {
    width: 500px;
}