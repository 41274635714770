.modal {
    width: 100%;
    max-width: 1000px;
    min-height: 300px;
    padding: 0;
}

.closeBtn {
    display: none;
}

.title {
    background-color: #669999;
    color: white;
    padding: 5px 10px;
    font-size: 1.4rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
}

.title svg {
    animation: svg 5s ease-in-out infinite;
}

.modalBody {
    padding: 1.2rem;
}

.filesBlock {
    margin-top: 1rem;
}

.filesBlock p {
    font-size: .9rem;
    font-weight: 500;
    color: rgb(83, 1, 1);
    margin-bottom: 1px;
}

.filesList {
    display: flex;
}

.quillBlock {
    margin-top: 1rem;
}

.quillBlock > div:first-child {
    font-size: .9rem;
    font-weight: 500;
    color: rgb(83, 1, 1);
    margin-bottom: 1px;
}

.quill {
    background-color: white;
}

.quill > div:last-child {
    height: 10rem;
    overflow: auto;
}

.buttons {
    display: flex;
    justify-content: center;
}

@keyframes svg {
    0% {
        transform: scale(0);
    }
    10% {
        transform: scale(1);
    }
    80% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(50px, -50px);
    }
}