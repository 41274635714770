.input {
}

.input > div:first-child {
  font-size: .9rem;
  font-weight: 500;
  color: rgb(83, 1, 1);
  margin-bottom: 1px;
}

.input input {
  width: 100%;
  border-radius: .4rem;
  border: 1px solid rgb(185 182 182);
  color: #313030;
  padding: 1px 0 1px 3px;
  font-weight: 400;
}

.input input::placeholder {
  font-size: 14px;
}

.validate {
  font-size: .8rem;
  font-weight: 400;
  color: red;
  margin: -0.1rem 0 0.2rem 0;
}