.wrapper {
    width: 100vw;
    height: 100vh;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.confirmWrapper {
    width: 100%;
    max-width: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 1rem 1rem 1rem;
}

.confirmTxt {
    font-size: 4rem;
    font-weight: 900;
    color: brown;
}

.confirmAbout {
    font-size: 1.2rem;
    text-align: center;
}

.confirmButton {
    font-size: .9rem;
    background-color: brown;
    padding: 1rem 1.2rem;
    color: #fff;
    border-radius: 100px;
    font-weight: 500;
    margin: 2rem 0;
    text-transform: uppercase;
    text-decoration: none;
}

.confirmImg {
    width: 100%;
    max-width: 400px;
}