.wrapper {
    width: 100%;
}

/* .tabs ul:first-child {
    display: flex;
    justify-content: center;
    border-bottom: none;
}

.tabs ul:first-child>li {
    background-color: transparent;
    border-bottom: none;
} */


.importBlock {
    width: 100%;
}

.importBlock input {
    display: none;
}

.buttonsBlock {
    display: flex;
    flex-wrap: wrap;
}

.buttonsBlock a {
    text-decoration: none;
    height: fit-content;
}

.confWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.253);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
}

.confirmation {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 20px;
    border: 3px solid #dbdbdb;
    border-radius: 3px;
}

.confirmation div {
    color: #000;
    font-size: 19px;
    text-align: center;
    font-weight: 500;
    overflow-wrap: anywhere;
}

.confirmation span {
    color: brown;
    border-bottom: 1px solid brown;
}

/* .confirmation div:last-child {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-around;
} */

/* .confirmation button {
    padding: 5px 30px;
    background-color: rgba(240, 248, 255, 0.473);
    border: 1px solid #000;
    border-radius: 5px;
    color: #000;
}

.confirmation button:first-child:hover {
    background-color: rgb(57, 216, 105);
    color: #000;
}

.confirmation button:last-child:hover {
    background-color: rgb(241, 70, 70);
    color: #000;
} */

.confirmation h3 {
    color: rgb(127 7 17);
}

.confirmation ul {
    margin-top: 20px;
    list-style: auto;
    width: 100%;
}

.importBlock label div, .dlbutton {
    margin: 20px 0 0 30px;
    padding: .5rem 10px;
    box-shadow: 0 0 5px rgb(46, 36, 36);
    border-radius: 4px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(175, 18, 18);
    color: aliceblue;
    transition: all 0.2s ease-in;
    cursor: pointer;
    height: fit-content;
}

.importBlock label div:hover, .dlbutton:hover {
    opacity: 0.8;
}

.importBlock label div svg, .dlbutton svg {
    margin-right: 1rem;
}

.importedBlock  {
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    width: 70%;
}

.importedBlock > div {
    /* width: 400px; */
}

.listTitle {
    padding: 10px 30px;
    background-color: rgb(57, 216, 105);
    color: antiquewhite;
    border: 1px solid rgb(209, 206, 206);
    border-radius: 3px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.peopleList {
    background-color: aliceblue;
    border: 1px solid rgb(209, 206, 206);
    border-top: none;
}

.peopleList div {
    padding: 5px 0;
    display: flex;
    flex-wrap: nowrap;
}

.peopleList div + div {
    border-top: 1px solid rgb(241, 232, 232);
}


.peopleList > div span:nth-child(1) {
    display: inline-block;
    width: 50px;
    text-align: center;
    border-right: 1px solid rgb(223, 212, 212);
}

.peopleList > div span:nth-child(2) {
    display: inline-block;
    margin: 0 10px;
    width: 300px;
    overflow: hidden;
    white-space: nowrap;
}


.peopleList > div span:nth-child(3) {
    border-left: 1px solid rgb(223, 212, 212);
    padding-left: 10px;
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
}

.checkBlock {
    position: absolute;
    top: 15px;
    right: -310px;
    padding: 10px !important;
    width: 300px;
    height: auto;
    background-color: rgba(255, 255, 255, 0.767);
    border: 1px solid rgb(223, 212, 212);
    color: grey;
    border-radius: 5px;

}

.instrWrapper {
    position: fixed;
    top: 0;
    left: 0;
    padding: 50px 0;
    width: 100%;
    height: 100vh;
    z-index: 11;
    background-color: rgba(0, 0, 0, 0.123);
    display: flex;
    justify-content: center;
}

.instruction {
    width: 1000px;
    min-height: 500px;
    background-color: white;
    box-shadow: 5px 5px 5px grey;
    overflow: auto;
    padding: 30px;
    /* user-select: none; */

}

.instruction::-webkit-scrollbar {
    width: 0;
}

.instruction h3 {
    text-align: center;
    margin-bottom: 20px;
}

.instruction a {
    color: brown;
    font-weight: 500;
}

.firstLevel {
    padding-left: 2rem;
}

.firstLevel ul {
    padding: 5px 0 5px 2rem;
}

.tableWrapper {
    height: 570px;
    max-width: 700px;
    width: 100%;
    overflow-y: auto;
    margin: 1rem auto 2rem 2rem;
}

.tableWrapper table {
    border-collapse: collapse;
    width: 100%;
    max-width: 700px;
    background-color: #fff;
    font-size: .9rem;
}
  
.tHead tr {
    background-color: rgb(148, 5, 53) !important;
    border-top: none !important;
    border-bottom: none !important;
    box-shadow: inset 0 1px 0 rgb(107, 105, 105), inset 0 -1px 0 rgb(107, 105, 105);
}

.tHead {
    position: sticky;
    top: -1px;
    left: 0;
    z-index: 1;
    color: rgb(245, 162, 107);
    user-select: none;
}

.tBody {
    color: #2e2e2e;
}

.tableWrapper td, .tableWrapper th {
    border: 1px solid rgb(107, 105, 105);
    text-align: left;
    padding: .4rem;
    cursor: pointer;
  }
  
.tableWrapper tr:nth-child(even) {
    background-color: rgba(240, 248, 255, 0.486);
  }

.tableWrapper tr:nth-child(odd) {
    background-color: rgba(247, 0, 255, 0.089);
}

.addPhoto {
    width: 240px;
    display: block;
    margin-bottom: 2rem;
}

.addPhoto div {
    font-size: 16px !important;
    color: #fff !important;
    margin: 2rem auto 0 !important;
    background-color: rgb(117, 194, 181) !important;
    box-shadow: none !important;
    user-select: none;
}

.buttonsWrapper {
    border-top: 2px solid #c2c2c2;
    padding-top: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.buttonYes {
    padding: .5rem 1.5rem;
    background-color: rgb(117, 194, 181);
    border: 2px solid #c2c2c2;
    border-radius: 1rem;
    color: #fff; 
    user-select: none;
}

.buttonNo {
    padding: .5rem 1.5rem;
    background-color: rgb(194, 122, 117);
    border: 2px solid #c2c2c2;
    border-radius: 1rem;
    color: #fff; 
    user-select: none;
}

.photoList {
    display: block;
    margin: 1rem 0 !important;
    height: 300px;
    overflow-y: auto;
    width: 100%;
}

.photoItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    transition: .2s;
    padding-right: 1rem;
}

.photoItem:hover {
    background-color: #c2c2c2;
}

.photoName {
    font-size: 16px;
    font-weight: 500;
    margin: .5rem 1rem;
    user-select: none;
    overflow-wrap: anywhere;
}

.photoIconClose {
    cursor: pointer;
    color: red;
    font-size: 25px;
    width: 25px;
    height: 25px;
}

.photoList::-webkit-scrollbar, .photoList::-webkit-scrollbar {
    background-color: rgba(238, 238, 238, 0.562);
    width: .4rem;
    height: .4rem;
  }
  
.photoList::-webkit-scrollbar-thumb, .photoList::-webkit-scrollbar-thumb {
    background-color: #c2c2c2;
    border-radius: 1rem;
}

@media screen and (max-width: 600px) {
    .tableWrapper {
        margin: 1rem auto;
    }
}