
.container {
    /* height: 500px; */
    max-width: 1100px;
    width: 100%;
}

.title {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  background: linear-gradient(45deg, rgb(185 26 26) 0%, rgb(253 196 126) 100%);
  padding: 0 16px;
  color: #ffffff;
  font-size: 23px;
  font-weight: 400;
}

.searchBlock {
  display: flex;
  margin-bottom: .5rem;
  margin-top: .5rem;
  flex-wrap: wrap;
}

.searchIcon {
  font-size: 24px;
  width: 24px;
  height: 24px;
  display: block;
  color: #000;
  cursor: pointer;
  margin-left: .5rem;
}

.regionSelect {
  border: 1px solid #cecece;
  margin-right: 1rem;
  max-width: 250px;
  margin-bottom: 1rem;
  height: 1.5rem;
  font-weight: 500;
  color: #616060;
  border-radius: 3px;
  outline: none;
}

.searchInput {
  width: 100%;
  height: 24px;
  border: 1px solid #cecece;
  background-color: #ffffff;
  color: #616060;
  font-size: 13px;
  font-weight: 400;
  border-radius: 3px;
  padding: 3px 6px;
  box-sizing: border-box;
  resize: none;
  width: 150px;
}

.newsList {
    width: 100%;
    min-height: 523px;
    height: calc(100% - 34px);
    padding: 0px 14px 70px;
    background-color: #fff;
    position: relative;
}

.newsListHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px 10px 4px;
}

.tableHeader {
    display: flex;
    align-items: center;
    min-height: 36px;
    border-bottom: 1px solid #c4c4c4;
  }

  .tableHeader div:first-child {
    color: #4c4c4c;
    font-size: 15px;
    font-weight: 700;
  }

  .tableRow {
    padding: 3px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d8d5d5;
  }

  .tableRow:hover {
    background-color: #fcf6f6b4;
  }
  
  .displayname {
    color: #919296;
    cursor: pointer;
    flex-basis: 100%;
  }

  .displayname span {
    position: relative;
    margin-left: 5px;
  }

  .displayname span::after {
    position: absolute;
    bottom: 5px;
    left: 0;
    content: "";
    height: 1px;
    width: 100%;
    background-color: rgb(75, 112, 109);
    opacity: 0;
    transition: all 0.2s ease-in;
  }

  .displayname:hover span {
    color: rgb(16, 86, 80);
    font-weight: 500;
  }
  
  .displayname:hover span::after {
    opacity: 1;
    transform: translateY(5px);
  }
  
  .username {
    flex-basis: 30%;
  }
  
  .superuser {
    flex-basis: 20%;
  }
  
  .actions {
    flex-basis: 10%;
  }

  .actions svg {
    color: rgb(204, 20, 20);
    cursor: pointer;
    font-size: 24px;
  }

  .actions svg:hover {
    color: #ff0000;
  }

  .deleteIcon {
    width: 20px;
    cursor: pointer;
  }

  .pagination {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
  }

  .controls {
    position: absolute;
    bottom: 25px;
    left: 25px;
    display: flex;
    align-items: center;
  }

  .sortBlock {
    border: 1px solid rgb(187, 184, 184);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.2s ease-in;
  }

  .sortBlock:active {
    background-color: rgb(250, 226, 192);
  }
  
  .sortActive {
    background-color: rgb(208, 253, 238);
    border-color: rgb(148, 146, 146);
  }
  
  .sortBlock svg {
    font-size: 20px;
    color: rgb(187, 184, 184);
  }

  .sortActive svg {
    color: rgb(148, 146, 146);
  }

  .sortBlock:hover {
    transform: scale(1.05);
  }
  
  .itemsCount {
    margin-left: 10px;
    user-select: none;
  }
  
  .itemsCount select {
    color: grey;
    cursor: pointer;
    outline: none;
    border-radius: 3px;
  }
  
  .itemsCount span {
    margin-left: 5px;
    color: grey;
  }

  /*==============================MODAL===================================*/

  .closeBtn {
    display: none;
  }
  
  .modal {
    max-width: 850px;
    width: 100%;
    height: auto;
    padding: 0;
    background-color: #fff;
    overflow: hidden;
  }

  .modalBody {
      width: 100%;
      padding: 15px 15px 30px;
  }

  .modalMain {

  }

.input {
  width: 100%;
  margin-left: .5rem;
  margin-top: .2rem;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid rgb(139, 138, 138);
  padding-left: 3px;
  font-size: 18px;
  font-weight: 400;
}

.mapBlock {
    margin-top: 15px;
    width: 100%;
    max-width: 320px;
}

.select {
    width: 90%;
    font-size: 18px;
    font-weight: 500;
}

.filesBlock {
    margin-top: 15px;
    width: 90%;
    word-break: break-all;
}

.fileItem {
    display: flex;
    padding-left: 10px;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    border: 1px dashed rgb(119, 118, 118);
}

.fileItem:hover {
  border: 1px solid rgb(119, 118, 118);
}

.fileItem a {
    width: 90%;
}

.fileItem + .fileItem {
    margin-top: 5px;
}

.imgBlock {
    margin: 4px 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.imgBlock img {
    max-width: 100%;
    max-height: 100%;
}

  .modalButtons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }

  .modalButtons button {
    width: 125px;
    height: 30px;
    margin: 0 20px;
    font-size: 16px;
    border-radius: 25px;
}

.imageBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.imageBlock button, .inputButton {
    margin-top: 3px;
    background-color: rgba(83, 85, 85, 0.082);
    padding: 5px 15px;
    font-size: 13px;
    border: 1px solid rgb(177, 171, 171);
    border-radius: 5px;
    color: rgb(119, 119, 119);
    cursor: pointer;
}

.imageBlock button:hover, .inputButton:hover {
    background-color: rgba(83, 85, 85, 0.26);
    transition: all 0.2s ease-in;
}

.image {
    border: 1px solid rgb(177, 171, 171);
    height: 180px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image img {
    max-width: 100%;
    max-height: 100%;
}

.inputsTop {
  width: 100%;
  max-width: 520px;
  display: block;
}

.titleInput:not(:first-child) {
  display: block;
}

.titleInput {
  display: block;
  width: 170px;
}

.mapTitle {
  font-size: 16px;
  margin: .5rem 0;
  display: block;
}

.aboutTitle {
  font-size: 16px;
  margin: 1rem 0 .5rem 0;
  display: block;
}

.firstBlock, .secondBlock {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}

.inputsBlock {
  width: 570px;
}

.inputBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: .4rem;
}

.titleInputTime {
  width: 170px;
}

.titleInput:nth-child(2) {
  width: 170px;
}

.regionsBlock {
  margin-top: 15px;
}

.regionsList {
  min-height: 100px;
  max-height: 240px;
  overflow-y: auto;
  margin: 10px 0;
}

.regionsList::-webkit-scrollbar {
  background-color: #eee;
  width: .5rem;
}

.regionsList::-webkit-scrollbar-thumb {
  background-color: rgb(95, 95, 95);
  border-radius: 1rem;
}

.hideBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 100%;
  font-weight: 500;
}

.hideBlock > div {
  margin-left: 10px;
}

.hideBlock > div > label > span {
  font-size: 15px;
}



.infoBlock {
  margin: 5px 0;
  font-size: 15px;
}

.infoBlock span:nth-child(1) {
  color: #615c5c;
  font-weight: 700;
}

.infoBlock span:nth-child(2) {
  padding: 0 5px;
  font-style: italic;
  font-weight: 500;
  color: rgb(126 5 5);
}

.infoBlock span:nth-child(3) {
  font-weight: 500;
  font-style: italic;
  color: rgb(126 5 5);
}

@media (max-width: 425px) {
  .title {
    font-size: 1rem;
  }

  .pagination {
    width: calc(100% - 2rem);
  }
}