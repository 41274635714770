.mailsWrapper {
    background-color: white;
    margin-top: 2rem;
    height: 55vh;
    width: 100%;
    max-width: 50%;
    border-radius: 4px;
    box-shadow: 0 0 5px rgb(226, 223, 223);
    padding: 10px;
    overflow: auto;
}

.mailsWrapper li {
    height: 100px;
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in;
}

.mailsWrapper li:hover {
    transform: scale(1.003);
}

.mailsWrapper li:nth-child(even) {
    background-color: rgb(244 244 244);
}

.mailsWrapper li > div:first-child {
    width: 70px;
    height: 70px;
    background-color: brown;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 30px;
}

.mailsWrapper li:nth-child(even) > div:first-child {
    background-color: rgb(202, 27, 27);
}

.mailDescription {
    margin-left: 10px;
}

.mailDescription div:first-child {
    font-weight: 600;
    font-size: 20px;
    color: rgb(71, 70, 70);
    position: relative;
}

.mailDescription div:first-child::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: brown;
  opacity: 0;
  width: 0px;
  transition: .5s;
}

.mailsWrapper li:hover .mailDescription div:first-child::after {
    opacity: 1;
    width: 100%;
}

.mailDescription div:last-child {
    margin-top: 10px;
    font-size: 16px;
    color: rgb(71, 70, 70);
    font-weight: 500;
    font-style: italic;
}