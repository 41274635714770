.container {
    width: 100%;
    min-height: 55vh;
    padding: 1rem 2rem 2rem;
}

.container input, .container textarea {
    outline: none;
}

.stageTitle {
    color: rgb(28, 133, 124);
    cursor: pointer;
    margin: 10px 0;
}

.stageTitleInput {
    font-size: 16px;
    color: rgb(65, 64, 64);
}

.goForward {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    border-bottom: 1px solid grey;
    width: 210px;
    color: grey;
    padding-bottom: 3px;
}

.navBlock {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
}

.goBack {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    border-bottom: 1px solid grey;
    width: 65px;
    color: grey;
    margin-bottom: 20px;
    padding: 0 4px 2px 0;
}

.goForward:hover, .goBack:hover {
    color: brown;
    border-bottom: 1px solid brown;
}

.stageWrap {
    width: 100%;
    position: relative;
    padding: 1rem 1rem 3rem 1rem;
    border: 1px solid rgb(184, 176, 176);
    border-radius: 10px;
    background-color: rgba(247, 244, 241, 0.692);
    box-shadow: 0 0 10px rgb(195 185 185);
}

.needCheckButton {
    position: absolute;
    bottom: .3rem;
    right: .3rem;
    background-color: rgb(28, 133, 124);
    color: aliceblue;
    padding: 5px;
    border-radius: 3px;
    transition: all 0.2s ease-in;
}

.needCheckButton:hover {
    opacity: 0.7;
}

.unopened:hover {
    opacity: 0.9;
}

.stageWrap + .stageWrap {
    margin-top: 20px;
}

.stageNavBlock {
    position: absolute;
    top: 7px;
    right: 10px;
    display: flex;
    align-items: center;
}

.stageNavBlock svg {
    cursor: pointer;
    color: rgb(109, 106, 106);
    transition: all 0.2s ease-in;
}

.stageNavBlock svg:hover {
    color: rgb(28, 133, 124);
    transform: scale(1.2);
}

.stageBlock > div {
    margin: 20px 0;
    word-break: break-word;
}

.stageBlock textarea {
    font-size: 16px;
    width: 70%;
    height: 100px;
    color: rgb(65, 64, 64);
    padding: 2px;
    resize: none;
}

.stageBlock span {
    margin: 0 3px;
}

.stageBlock input {
    font-size: 16px;
    color: rgb(65, 64, 64);
}

.stageBlockTitle {
    margin: 10px 0;
    color: rgb(105, 14, 14);
}

.stageDescriptionBlock {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.stageDescriptionBlock > div {
    flex-basis: 49%;
}

.datesBlock {
    display: flex;
    flex-wrap: wrap;
}

.datesBlock > * {
    margin: .1rem 0;
}

.quill {
    background-color: white;
}

.quill > div:last-child {
    max-height: 16rem;
    overflow: auto;
}

.checkboxesBlock {
    display: flex;
    flex-wrap: wrap;
}

.checkboxesBlock > * {
    width: 250px;
}

.checkboxesBlock span {
    width: 130px;
}

.closeNewStageButton {
    position: absolute;
    top: 7px;
    right: 7px;
    color: rgba(255, 0, 0, 0.616) !important;
    cursor: pointer;
}

.questionItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 5px;
    width: 500px;
    margin-top: 10px;
}

.questionItem:hover {
    background-color: rgb(243, 240, 236);
}

.questionItemDiv {
    min-width: 400px;
    min-height: 20px;
    cursor: pointer;
}

.questionItem svg {
    padding: 0 5px;
    cursor: pointer;
}

.questionItem input {
    width: 400px;
    padding: 2px 0;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 16px;
    font-style: italic;
}

.questionItem div:last-child {
    height: 100%;
    border-left: 1px solid grey;
}

.stageRow {
    display: flex;
    flex-wrap: wrap;
}

.stageRow > * + * {
    margin-left: 3rem;
}

.pointsBlock input {
    width: 50px;
}

.stageNumberBlock input {
    width: 50px;
}

.imageBox {
    margin-top: 1rem;
    width: 250px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(187 184 184);
    border-radius: 10px;
    box-shadow: 0 0 10px rgb(187 184 184);
    position: relative;
    overflow: hidden;
    background-color: rgb(208 208 208);
}

.imageBox img {
    max-width: 100%;
    max-height: 100%;
}

.imageBox > svg {
  color: red;
  font-size: 26px;
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  transition: all 0.2s ease-in;
  animation: appearance 0.2s ease-in;
  cursor: pointer;
}

.imageBox > svg:first-child {
  font-size: 18px;
  top: 3px;
  right: 20px;
  color: #203372;
}

.imageBox > svg:hover {
  transform: scale(1.2);
}

.imageBox:hover svg {
    display: block;
}

.addImageBox {
    width: 120px;
    height: 100px;
    margin: .5rem 0;
}

.addQuestion {
    border: 2px dotted rgb(88, 88, 88);
    border-radius: 2px;
    color: rgb(82, 80, 80);
    width: 32px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.addQuestion:hover {
    border: 2px solid;
    color: rgb(77, 76, 76);

}

.requestItem {
    margin-top: 5px;
    position: relative;
}

.reqItem {
    border: 1px solid grey;
    border-radius: 5px;
    padding: 2px;
    display: flex;
    background-color: rgba(247, 204, 138, 0.151);
    color: rgb(78, 74, 74);
    position: relative;
    margin-right: 20px;
}

.first {
    background-color: rgb(227, 238, 238);
    color: rgb(119, 20, 20);
    font-weight: 500;
}

.first div {
    text-align: center;
}

.reqItem + .reqItem {
    margin-top: 5px;
}

.reqItem div {
    padding: 2px 5px;
    overflow: hidden;
}

.reqItem div + div {
    border-left: 1px solid grey;
}

.reqItem div:nth-child(1) {
    width: 16%;

}

.reqItem div:nth-child(2) {
    width: 13%;

}

.reqItem div:nth-child(3) {
    width: 16%;

}

.reqItem div:nth-child(4) {
    width: 14%;
}

.reqItem div:nth-child(5) {
    width: 21%;
}

.reqItem div:nth-child(6) {
    width: 20%;
}

.reqItem select {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    cursor: pointer;
    color: rgb(78, 74, 74);
    background-color: transparent;
}

.modal {
    position: absolute;
    top: -4px;
    right: 235px;
    width: 380px;
    max-height: 400px;
    border: 1px solid rgb(80, 73, 73);
    border-radius: 10px;
    background-color: rgb(247, 242, 242);
    z-index: 2;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition-delay: 0.5s;
    transition-property: all;
    opacity: 0;
    visibility: hidden;
}

.modal h4 {
    color: rgb(68, 65, 65);
}

.modal > div {
    width: 100%;
    max-height: 500px;
    overflow-y: auto;
}

.modal > div::-webkit-scrollbar {
    width: 6px;
}

.modal > div::-webkit-scrollbar-thumb {
    background-color: rgb(78, 71, 71);
}

.modal >textarea {
    width: 100%;
    margin-top: 5px;
    outline: none;
    border-radius: 5px;
    min-height: 70px;
    padding: 2px;
}

.modalButtons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 5px;
}

.answerItem {
    margin-top: 5px;
}

.answerItem div:first-child {
    font-weight: 500;
    text-decoration: underline;
    color: rgb(68, 65, 65);
    cursor: pointer;
    position: relative;
}

.answerItem > div:first-child svg {
    position: absolute;
    top: 0;
    right: 1rem;
}

.answerItem div:nth-child(2) {
    font-style: italic;
}

.answerItem div:last-child {
    font-weight: 500;
    color: rgb(68, 65, 65);
}

.answerItem div:last-child div {
    font-weight: 400;
    margin-left: 5px;
}
.answerBox {
    display: flex;
    flex-direction: column;
}
.answerBoxItems {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.answerBoxItems p {
    text-decoration: none;
    white-space: nowrap;
    word-wrap: break-word;
}
.questionItem{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    width: 190px;
    border: none;
    padding: 0px;
    margin-top: 5px; 
    margin-bottom: 5px;
    
}

.questionItem >p {
    margin-right:  12px;

}
.questionItem div:last-child {
    border-left: none;
    text-decoration: underline;
}

.newStageWrap {
    margin-top: 20px;
    border: 1px solid rgb(133 128 128);
    background-color: rgba(247, 244, 241, 0.692);
    box-shadow: 0 0 10px rgb(195 185 185);
    border-radius: 5px;
    padding: 8px;
    width: 100%;
    position: relative;
}

.newStageWrap span {
    margin: 0 3px;
}

.newStageWrap textarea {
    font-size: 16px;
    width: 80%;
    height: 100px;
    resize: none;
    color: rgb(65, 64, 64);
    padding: 2px;
}

.firstAddBlock input {
    font-size: 16px;
    color: rgb(65, 64, 64);
    width: 100%;
    max-width: 300px;
}

.thirdAddBlock input {
    font-size: 16px;
    color: rgb(65, 64, 64);
    width: 50px;
}

.fourAddBlock input {
    width: 100px;
    color: rgb(65, 64, 64);
    font-size: 16px;
}

.closeButton {
    position: absolute;
    top: 3px;
    right: 3px;
    cursor: pointer;
    font-size: 32px;
    color: rgb(28, 133, 124);
    transition: all 0.2s ease-in;
}

.closeButton:hover {
    transform: scale(1.2);
}

.addStageButton {
    background-color: rgb(28, 133, 124);
    color: aliceblue;
    padding: 5px;
    border-radius: 3px;
    margin-top: 5px;
    transition: all 0.2s ease-in;
    margin-top: 10px;
}

.addStageButton:hover {
    opacity: 0.9;
}

.addStageTitle {
    margin: 3px 0;
    color: rgb(105, 14, 14);
    font-weight: 500;
}

.downloadExcel {
    background-color: rgb(117, 194, 181);
    width: 160px;
    color: #fff;
    padding: .5rem 1rem;
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: .2rem;
    cursor: pointer;
}

.downloadExcel i {
    width: 1rem;
    height: 1rem;
}

.filesBlock {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    width: 70%;
}

.fileItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    margin: 20px;
    position: relative;
    padding-top: 10px;
    cursor: pointer;
}

.fileItem div {
    font-weight: 500;
    word-break: break-all;
}

.fileItem div:hover {
    text-decoration: underline;
}

.closeSvg {
    position: absolute;
    top: 2px;
    right: 2px;
    font-size: 26px;
    transition: all 0.2s ease-in;
}

.closeSvg:hover {
    transform: scale(1.2);
}

.pdfSvg {
    font-size: 60px;
    color: rgb(223, 29, 29);
}

.addFileButton {
    margin: 1rem 1rem 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    background-color: #d81717;
    padding: .5rem 1rem;
    font-size: .9rem;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-weight: 500;
}

.addFileButton i {
    font-size: 20px;
    width: 20px;
    height: 20px;
    margin-left: 0.5rem;
}

.fileButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: 0 auto;
}

.filesList div {
    display: flex;
    align-items: center;
    color: rgb(110, 109, 109);
}

.filesList span:last-child {
    padding-left: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.downloadExcelLoad {
    background-color: grey;
    width: 180px;
    color: #fff;
    padding: .5rem 1rem;
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: .2rem;
    cursor: progress;
}

.downloadExcel i, .downloadExcelLoad i {
    width: 1rem;
    height: 1rem;
}

.buttonOk {
    font-size: 13px;
    margin-right: .5rem;
    margin-top: .5rem;
    background-color: rgb(28, 133, 124) !important;
    color: #fff;
    padding: .3rem .5rem;
    border-radius: .2rem;
}

.buttonСancel {
    font-size: 13px;
    margin-right: .5rem;
    margin-top: .5rem;
    background-color: rgba(255, 0, 0, 0.616) !important;
    color: #fff;
    padding: .3rem .5rem;
    border-radius: .2rem;
}

.buttonsBlock {
    position: absolute;
    right: 0;
    bottom: 1rem;
}

.iconBlock {
    position: relative;
    width: fit-content;
}

.icon {
    width: 40px;
    height: 40px;
    border: 2px solid grey;
    border-radius: 5px;
    cursor: pointer;
}

.icon:hover {
    opacity: 0.8;
}

.icon img {
    max-width: 100%;
    max-height: 100%;
}

.iconList {
    position: absolute;
    top: 50px;
    z-index: 2;
    background-color: white;
    display: grid;
    grid-template-columns: repeat(8, 30px);
    grid-template-rows: repeat(4, 30px);
    padding: 5px;
    border-radius: 5px;
    border: 1px solid rgba(128, 128, 128, 0.336);
}

.iconList div {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.iconList img {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
}

.iconList div:hover, .iconList img:hover {
    border: 1px solid rgba(128, 128, 128, 0.425);
    border-radius: 3px;
}

.taskModule {
    width: 80%;
    height: 150px;
    overflow-y: auto;
    border: 1px solid #d4cdcd;
    margin-bottom: 20px;
    padding: 5px;
}

.taskModule a {
    margin: 3px 0;
    color: #4a4949;
}

.taskModule p:hover {
    color: rgb(215, 0, 27);
}

.categoriesBlock {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    max-width: 75%;
}

.categoriesBlock > div {
    background-color: rgb(98 175 168);
    color: white;
    padding: .2rem .5rem;
    border: 1px solid white;
    border-radius: 5px;
    box-shadow: 0 0 5px rgb(123 209 201);
    margin: .2rem;
    font-style: italic;
    cursor: pointer;
    position: relative;
}

.catItem svg {
    position: absolute;
    right: 0;
    top: calc(50% - 11px);
    font-size: 22px;
    transition: 0.2s;
    display: none;
}

.catItem svg:hover {
    transform: scale(1.3);
}

.catItem:hover svg {
    display: block;
}

.categoriesBlock > div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
}

.categoriesBlock input {
    width: 100%;
    max-width: 100%;
    border: none;
    outline: none;
    font-size: inherit;
    font-style: inherit;
    color: inherit;
    background-color: transparent;
}


.categoryDescription {
    position: absolute;
    top: calc(100% + .5rem);
    left: calc(50%);
    z-index: 1;
    color: rgb(99, 98, 98);
    background-color: white;
    border: 1px solid rgb(98 175 168);
    border-radius: 10px;
    padding: .5rem;
    width: 300px;
    box-shadow: 0 0 10px rgb(182, 181, 181);
}

.categoriesBlock h3 {
    text-align: center;
}

.categoriesBlock textarea {
    width: 100%;
    max-width: 100%;
    border: 1px solid rgb(98 175 168);
    outline: none;
    border-radius: 5px;
    padding: 2px;
    font-size: 1.1rem;
    color: rgb(99, 98, 98);
}

.closeButton {
    display: none;
    max-width: none;
}

.badgeContainer {
    display: flex;
    flex-wrap: wrap;
    /* max-height: 80vh; */
    width: 650px;
    overflow: auto;
}

.pageBreaker {
    page-break-after: always;
}

.badgeWrap {
    width: 266px;
    height: 381px;
    margin: 20px;
    background: url('../../assets/images/background.png');
    background-position: bottom;
    padding: 5px 15px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.firstDiv {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.firstDiv > div {
    width: 22%;
}

.firstDiv img {
    max-width: 100%;
}

.firstDiv h1 {
    color: rgb(201, 2, 2);
    font-size: 20px;
    font-weight: 800;
}

.secondDiv {
    margin-top: -10px;
    text-transform: uppercase;
    font-size: 13px;
    width: 100%;
    text-align: center;
    padding: 0 10px;
    color: rgb(128 15 15);
}

.thirdDiv {
    height: 120px;
    width: 100%;
    display: flex;
}

.thirdDiv > div:first-child {
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.thirdDiv img {
    max-width: 100%;
    max-height: 100%;
}

.thirdDescrDiv {
    width: 60%;
    height: 100%;
}

.thirdDescrDiv div:first-child {
    font-size: 16px;
    text-align: center;
    margin-top: 15px;
    line-height: 1.1;
}

.thirdDescrDiv div:last-child {
    margin-top: 10px;
    text-decoration: underline;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
}

.departmentDiv {
    font-size: 9px;
    margin-top: 7px;
    width: 100%;
}

.dateDiv {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 14px;
    color: rgb(80, 4, 4);
}

.qrcodeDiv {
    position: absolute;
    left: 10px;
    bottom: 10px;
}

.toggle {
    position: absolute;
    top: 4px;
    left: 2px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 24px;
}

.switch input {
  display:none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}
.switch input:disabled + .slider {
  opacity: 0.5;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: brown;
}

input:focus + .slider {
  box-shadow: 0 0 1px brown;
}

input:checked + .slider:before {
  -webkit-transform: translateX(34px);
  -ms-transform: translateX(34px);
  transform: translateX(34px);
}

.statButton {
    position: absolute;
    bottom: .5rem;
    right: .5rem;
    margin: 0 !important;
    cursor: pointer;
    color: rgb(105, 14, 14);
}

.statButton:hover {
    text-decoration: underline;
}

.statModal {
    width: 100%;
    max-width: 800px;
}

.statModal > h2 {
    text-align: center;
    color: #7c1111;
}

.statModal > table {
    margin-top: 1rem;
    border-collapse: collapse;
    width: 100%;
}

.statModal td, .statModal th {
    padding: .4rem;
    border: none;
}

.statModal th {
    color: #737373;
}

.statModal td {
    text-align: center;
    color: #4b4a4a;
}

.statModal tbody tr:nth-child(odd) {
    background-color: #e1dfdf;
}

.checkStagesBlock {
    margin-top: 1rem;
}

.checkStagesBlock > ul {
    list-style: none;
    width: fit-content;
}

.checkStagesBlock > ul > li {
    margin-top: 0.1rem;
    margin-left: 0.2rem;
    font-size: 1.1rem;
    color: #8f3e3e;
    cursor: pointer;
    text-decoration: underline;
}

.checkStagesBlock > ul > li:hover {
    color: #501818;
}


@media screen and (max-width: 700px) {

    .stageRow {
        display: block;
    }

    .stageRow > * {
        margin-left: 0px;
    }

    .stageDescriptionBlock {
        display: block;
    }

}