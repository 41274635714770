.ql-editor {
    min-height: 300px !important;
}
.text-editor {
    width: 100%;
    margin: 0 auto;
}
/* .ql-flip {
    top: 0 !important;
    left: 0 !important;
} */

.ql-syntax {
    background-color: rgba(0, 0, 0, .7) !important;
}