.button {
    margin-top: 3px;
    background-color: rgba(83, 85, 85, 0.082);
    padding: 5px 15px;
    font-size: 13px;
    border: 1px solid rgb(177, 171, 171);
    border-radius: 5px;
    color: rgb(119, 119, 119);
    cursor: pointer;
}

.button:hover {
    background-color: rgba(83, 85, 85, 0.26);
    transition: all 0.2s ease-in;
}

.button[disabled] {
    pointer-events: none;
}