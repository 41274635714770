.chart {
    height: 15vh;
    width: 18vh;
    background-color: #f09522;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.box {
    width: 100%;
    height: 300px;
    background-color: #af0300;   
    display: flex;
    justify-content: center;
    align-items: center;
    color: white !important;
    font-size: 30px !important;
    font-weight: 700;
    animation: box 0.8s ease-in;
}

@keyframes box {
    from { opacity: 0; }
    to { opacity: 1; }
}