.listWrap h2 {
    text-align: center;
    margin: 1rem 0;
    color: #6a1919;
}


.listTitles {
    display: flex;
    justify-content: center;
}

.listTitles div {
    padding-left: 2rem;
    font-weight: 500;
    margin: .5rem 0;
    color: #6a1919;
}

.listTitles > div:nth-child(1) {
    width: 300px;
}

.listTitles > div:nth-child(2) {
    width: 300px;
}

.listTitles > div:nth-child(3) {
    width: 150px;
}

.listTitles > div:nth-child(4) {
    width: 200px;
}

.listTitles > div:nth-child(5) {
    width: 200px;

}

.listWrap ul {
    list-style: none;
}

.listWrap > ul {
    border-radius: .5rem;
    overflow: hidden;
}

.row {
    border-bottom: 1px solid rgb(202, 200, 200);
    display: flex;
    padding: .5rem;
    cursor: pointer;
    color: #6a1919;
    width: fit-content;
}

.row > div {
    display: flex;
    align-items: center;
    overflow: hidden;
}

.row > div + div {
    border-left: 1px solid rgb(202, 200, 200);
    padding-left: .5rem;
}

.row > div:nth-child(1) {
    width: 300px;
    font-weight: 500;
}

.row > div:nth-child(2) {
    width: 300px;
}

.row > div:nth-child(3) {
    width: 150px;
    word-break: break-all;
}

.row > div:nth-child(4) {
    width: 200px;
    word-break: break-all;
}

.row > div:nth-child(5) {
    width: 200px;
}

.row a {
    color: inherit;
}

.noDataRow {
    padding: .5rem;
    text-align: center;
    border-bottom: 1px solid rgb(202, 200, 200);
    min-height: 36px;
    position: relative;
}

.listWrap + li {
    width: fit-content;
}

.listWrap > ul {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.listWrap ul > li {
    background-color: rgb(220 229 247);
}

.listWrap > ul > li:last-child > .row, .listWrap > ul > li:last-child .noDataRow {
    border-bottom: none;
}

.listWrap ul ul > li {
    background-color: rgb(193, 233, 229);
}

.listWrap ul ul ul > li {
    background-color: aliceblue;
    pointer-events: none;
}

.listWrap ul ul ul div:nth-child(5) {
    pointer-events: initial;
}


@media (max-width: 1200px) {
    
    .listWrap {
        font-size: 12px;
    }

    .listTitles div {
        padding-left: 1rem;
    }

    .listTitles > div:nth-child(1), .row > div:nth-child(1) {
        width: 200px;
    }
    .listTitles > div:nth-child(2), .row > div:nth-child(2) {
        width: 200px;
    }
    .listTitles > div:nth-child(3), .row > div:nth-child(3) {
        width: 100px;
    }
    .listTitles > div:nth-child(4), .row > div:nth-child(4) {
        width: 130px;
    }
    .listTitles > div:nth-child(5), .row > div:nth-child(5) {
        width: 130px;
    }

}

@media (max-width: 800px) {
    
    .listWrap {
        font-size: 9px;
    }

    .listTitles > div:nth-child(1), .row > div:nth-child(1) {
        width: 100px;
    }
    .listTitles > div:nth-child(2), .row > div:nth-child(2) {
        width: 100px;
    }
    .listTitles > div:nth-child(3), .row > div:nth-child(3) {
        width: 50px;
    }
    .listTitles > div:nth-child(4), .row > div:nth-child(4) {
        width: 60px;
    }
    .listTitles > div:nth-child(5), .row > div:nth-child(5) {
        width: 60px;
    }

}