.wrapper {
    width: 100%;
    height: auto;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrapper img {
    margin: 20px 0;
}

.question {
    background-color: rgba(117, 117, 117, 0.582);
    border: 1px solid gray;
    width: 70%;
    margin-top: 50px;
    padding: 30px;
    border-radius: 20px;
    text-align: center;
    color: white;
    font-size: 24px;
}

.answer {
    width: 70%;
    margin-top: 100px;
    display: flex;
    justify-content: space-evenly;
}

.button {
    background-color: rgba(117, 117, 117, 0.582);
    border: 1px solid gray;
    border-radius: 10px;
    width: 200px;
    padding: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;   
    cursor: pointer; 
}

.imgButton {
    background-color: rgba(117, 117, 117, 0.582);
    border: 1px solid gray;
    border-radius: 10px;
    width: 300px;
    height: 300px;
    padding: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; 
}

.imgButton img {
    max-width: 100%;
    max-height: 100%;
}

.button:hover, .imgButton:hover {
    outline: 1px solid gray;
    color:rgb(236, 235, 226);
    transform: scale(1.05);
    transition: all 0.2s ease-in;
}

.red {
    background-color: rgba(255, 0, 0, 0.63);
}

.green {
    background-color: rgba(0, 128, 0, 0.63);
}
