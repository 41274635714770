.wrapperSliderEquipment {
    width: 100%;  
    max-width: 300px;
  }
  
  .equipment__small .photoWrapper {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
  
  
  .deletePhoto {
    position: absolute;
    right: 0;
    top: 0;
    color: red;
    font-size: 28px;
    z-index: 9;
  }

  .search {
    position: absolute;
    right: 40px;
    top: 0;
    color: #75c2b5;
    font-size: 28px;
    z-index: 9;
  }
  
  .wrapperSliderEquipmentBig img {
    height: 300px;
    object-fit: contain;
  }
  
  .wrapperSliderEquipmentSmall img {
    height: 100px;
    object-fit: contain;
  }

.bg {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    background-color: rgba(128, 128, 128, .9);
}

.popup {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup img {
    height: 100%;
    max-height: 80vh;
    width: 90vw;
    max-width: 1000px;
    object-fit: contain;
}

.popup_close {
    color: #cc0606;
    font-size: 2rem;
    padding: 1rem;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 99999;
}

.rotateBlock {
  z-index: 1000;
  color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  font-size: 80px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

/* __________________SLIDER_________________ */
  
  .sliderWrapper {
    width: 300px;
    margin: 0 auto;
  }


  @media (max-width: 1024px) {
    .personalDataLeft {
        width: 100%;
        flex-direction: column;
    }

    .personalDataBlock {
        flex-wrap: wrap;
        justify-content: center;
    }

    .sliderWrapper {
        width: 250px;
        margin: 1rem auto;
      }

      .description {
        margin: 0 1rem;
    }

    .dataRow {
        display: flex;
        flex-wrap: wrap;
    }

    .buttons {
        justify-content: center;
        flex-wrap: wrap;
    }

    .buttons button{
        margin-bottom: 1rem;
    }

    .documentItem {
        margin-bottom: 1rem;
    }

    .fio {
        font-size: 1.5rem;
        padding: 1rem;
        word-break: break-all;
    }
  }

  .documentsBlock {
    width: 100%;
    margin: 50px 0;
}

.documentItem {
    max-width: 400px;
    width: 100%;
    height: 300px;
    background-color: rgb(223, 219, 219);
    color: grey;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapperSl {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
}






/* -----------------------------------------------MODAL------------------------------------------------------------------------------ */

.modal {
  width: 100%;
  min-width: 600px;

  > h1 {
    text-align: center;
  }

  > div:nth-child(2) {
    margin-top: 1rem;
  }

  .buttonsBlock {
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    button + button {
      margin-left: 1rem;
    }
  }
}