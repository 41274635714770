.container {
    max-width: 1100px;
    width: 100%;
}

.title {
    width: 100%;
    height: 34px;
    display: flex;
    align-items: center;
    background: linear-gradient(45deg, rgb(185 26 26) 0%, rgb(253 196 126) 100%);
    padding: 0 16px;
    color: #ffffff;
    font-size: 23px;
    font-weight: 400;
}

.newsList {
    width: 100%;
    min-height: 523px;
    height: calc(100% - 34px);
    padding: 0 14px 70px;
    background-color: #fff;
    position: relative;
}

.newsListHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px 10px 4px;
}

.tableHeader {
    display: flex;
    align-items: center;
    min-height: 36px;
    border-bottom: 1px solid #c4c4c4;
}
.tableHeader div:first-child {
    color: #4c4c4c;
    font-size: 15px;
    font-weight: 700;
}

.tableRow {
    padding: 3px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d8d5d5;
}
.tableRow:hover {
    background-color: #fcf6f6b4;
}

.displayname {
    color: #5b5c61;
    cursor: pointer;
    flex-basis: 70%;
    margin-right: 0.5rem;
}

.displayname span {
    position: relative;
    margin-left: 5px;
}

.displayname span::after {
    position: absolute;
    bottom: 5px;
    left: 0;
    content: "";
    height: 1px;
    width: 100%;
    background-color: rgb(75, 112, 109);
    opacity: 0;
    transition: all 0.2s ease-in;
}
.displayname:hover span {
    color: rgb(16, 86, 80);
    font-weight: 500;
}
.displayname:hover span::after {
    opacity: 1;
    transform: translateY(5px);
}

.superuser {
    flex-basis: 30%;
    color: #4c4c4c;
    font-size: 15px;
    font-weight: 700;
}

.actions {
    flex-basis: 5%;
}

.actions svg {
    color: rgb(204, 20, 20);
    cursor: pointer;
    font-size: 24px;
}
.actions svg:hover {
    color: #ff0000;
}

.deleteIcon {
    width: 20px;
    cursor: pointer;
}

.pagination {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
}

.closeBtn {
    display: none;
}

.modal {
    max-width: 840px;
    width: 100%;
    height: auto;
    padding: 0;
    background-color: #fff;
    overflow: initial;
}

.modalBody {
    width: 100%;
    padding: 15px 15px 30px;
}
.modalBody > div:first-child > div {
    margin-top: 8px;
}

.mapBlock {
    margin-top: 15px;
    width: 100%;
    /* max-width: 320px; */
}

.mapBlock > span {
    font-size: 0.9rem;
    font-weight: 500;
    color: rgb(83, 1, 1);
    margin-bottom: 1px;
}

.input {
    width: 90%;
    font-size: 18px;
    font-weight: 500;
}

.select {
    width: 90%;
    font-size: 18px;
    font-weight: 500;
}

.filesBlock {
    margin-top: 15px;
    width: 90%;
    word-break: break-all;
}

.fileItem {
    display: flex;
    padding-left: 10px;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    border: 1px dashed rgb(119, 118, 118);
}
.fileItem:hover {
    border: 1px solid rgb(119, 118, 118);
}

.fileItem a {
    width: 90%;
}

.fileItem + .fileItem {
    margin-top: 5px;
}

.imgBlock {
    margin: 4px 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.imgBlock img {
    max-width: 100%;
    max-height: 100%;
}

.input {
    margin-top: 10px;
}

.modalButtons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.modalButtons button {
    width: 125px;
    height: 30px;
    margin: 0 20px;
    font-size: 16px;
    border-radius: 25px;
}

.pdf {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: 1rem 0;
    padding: 0.5rem;
    border: 1px solid rgba(0,0,0,.5);
    border-radius: 0.2rem;
}

.imagePDF {
    color: red;
    font-size: 30px;
    height: 30px;
    width: 30px;
    margin: 0 0.5rem 0 0;
}

.titlePDF {
    font-size: 16px;
}

.linkpdf {
    display: contents;
    color: #000;
}

.toTrash {
    color: red;
    font-size: 25px;
    width: 25px;
    height: 25px;
    margin: 0 0 0 3rem;
    cursor: pointer;
}

.datesBlock {
    display: flex;
    align-items: end;

    > div + div {
        margin-left: calc(50% - 152px);
    }
}

.chiefPhotosBlock {

    > p {
        font-size: 0.9rem;
        font-weight: 500;
        color: rgb(83, 1, 1);
        margin-top: 0.5rem;
    }

    > div {
        display: flex;
        flex-wrap: wrap;
    }
}

.awardsBlock {

    > p {
        font-size: 0.9rem;
        font-weight: 500;
        color: rgb(83, 1, 1);
        margin-top: 0.5rem;
    }

    > div {
        // display: flex;
        // justify-content: space-between;
    }
}

.awardItem {
    > div:nth-child(2) {
        min-height: 50px;
        display: flex;
        flex-wrap: wrap;

        > img {
            max-width: 100%;
            max-height: 100%;
        }

        > p {
            color: grey;
            letter-spacing: -1px;
        }
    }
}

.staffBlock {
    position: relative;
    border: 1px solid rgb(185 182 182);
    border-radius: 0.4rem;
    padding: 1rem;
    margin-top: 1rem;

    > p {
        position: absolute;
        top: -.9rem;
        background-color: #fff;
        padding: 0.3rem;
        color: rgb(141 140 140);
    }

    > div {
        margin-top: 0.5rem;
    }

}

.invisibleBlock {
    height: 0;
}

.fileModalButton {
    width: 70px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #ced4da;
    border-radius: 0.5rem;
    cursor: pointer;
}
.fileModalButton:hover {
    border-style: solid;
}
@media (max-width: 425px) {
    .title {
        font-size: 1rem;
    }

    .pagination {
        width: calc(100% - 2rem);
    }
}
