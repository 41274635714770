
.content {
    display: flex;
    flex-direction: column;
    padding-top: 140px;
    align-items: center;
}

.content h1 {
    margin-top: 50px;
    color: brown;
}

.avatar {
    pointer-events: none;
    user-select: none;
}

@media (max-width: 768px) {
    .content {
        width: 100%;
        padding-top: 80px;
    }
    .content h1 {
        width: 70%;
        font-size: 16px;
        margin-top: 10px;
    }
}
