.wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.image {
    width: 25vw;
    height: 25vw;
    background-repeat: no-repeat;
    background-size: contain;
}