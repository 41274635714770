.main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid rgb(175, 150, 150);
    border-radius: 10px;
    box-shadow: 0 0 10px rgb(41 133 126);
    padding: 5px;
    background-color: white;
    color: rgb(28, 133, 124);
    position: relative;
}

.main + .main {
    margin-top: 30px;
}

.index {
    width: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-weight: 500;
}

.questionBlock, .questionPreviewBlock {
    width: 85%;
}

.questionPreviewBlock {
    display: flex;
    align-items: center;
}

.questionBlock a {
    color: inherit;
}

.question, .questionInput {
    margin-bottom: 5px;
    min-width: 300px;
}

.iconButtons {
    position: absolute;
    bottom: .5rem;
    right: .5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.iconButtons i {
    width: 24px;
    height: 24px;
}

.imageBox {
    width: 120px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(187 184 184);
    border-radius: 10px;
    box-shadow: 0 0 10px rgb(187 184 184);
    position: relative;
    overflow: hidden;
}

.imageBox img {
    max-width: 100%;
    max-height: 100%;
}

.imageBox > svg {
  color: red;
  font-size: 26px;
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  transition: all 0.2s ease-in;
  animation: appearance 0.2s ease-in;
  cursor: pointer;
}

.imageBox > svg:first-child {
  font-size: 18px;
  top: 3px;
  right: 20px;
  color: #203372;
}

.imageBox > svg:hover {
  transform: scale(1.2);
}

.imageBox:hover svg {
    display: block;
}

.questionInput {
    width: 100%;
}

.imagesBlock {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.imagesBlock div {
    width: 100px;
    height: 60px;
    margin: 5px;
}

.imagesBlock img {
    max-width: 100%;
    max-height: 100%;
}

.editImageItem {
    position: relative;
}

.editImageItem svg {
    position: absolute;
    top: 2px;
    right: 2px;
    cursor: pointer;
    color: rgb(204, 250, 244);
}

.answers {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 15px;
}

.pointsRow {
    display: flex;
    justify-content: flex-end;
}

.answers div {
    color: grey;
}

.answers > div:first-child {
    color: rgb(28, 133, 124);
}

.inputFirstChild {
    width: 20px;
    border: none;
    outline: none;
    padding-left: 2px;
}

.inputLastChild {
    width: 100%;
    margin: 5px 0;
    border: none;
    outline: none;
    margin-left: 3px;
    padding-right: 30px;
}

.answerBlock + .answerBlock {
    margin-top: .5rem;
    border-top: 1px dashed #dbdbdb;
}

.answerItem {
    width: 100%;
    color: rgb(20, 99, 79) !important;
    margin: 3px 0;
    display: flex;
}

.answerItem div:first-child {
    width: 90%;
}

.answerItem div:last-child {
    width: 10%;
    text-align: center;
}


.editAnswerItem {
    display: flex;
    align-items: center;
}

.editAnswerItem > input {
    width: 10%;
    border: 1px solid grey;
    border-radius: 3px;
    height: 25px;
}

.editAnswer {
    position: relative;
    display: flex;
    align-items: center;
    width: 90%;
    height: 25px;
    border: 1px solid grey;
    border-radius: 3px;
    margin: 5px;
}

.categoryBlock {
    margin-bottom: .2rem;
    font-size: .9rem;
    font-style: italic;
}

.categoryBlock span {
    color: rgb(105, 14, 14);
}

.categoryBlock select {
    color: rgb(105, 14, 14);
    border: none;
    outline: none;
    font-style: italic;
}

.categoryBlock select:disabled {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

.selectedBlock {
    width: 30px;
    height: 100%;
    border-right: 1px solid grey;
    border-left: 1px solid grey;
}

.deleteItem {

    color: rgba(255, 0, 0, 0.616);
    position: absolute;
    right: 1px;
    top: 1.5px;
}

.addButton {
    margin: 5px 0;
}

.editButton {
    width: 5%;
    /* height: fit-content; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    transition: .2s;
}

.editButton:hover {
    transform: scale(1.2);
}

.correctAnswerBlock {
    margin: 0 10px;
    width: 10%;
    text-align: center;
}

.correctAnswerBlock div:last-child {
    text-align: center;
    font-weight: 700;
    font-size: 22px;
    margin-top: 5px;
}

.correctAnswerBlock input {
    width: 50px;
}

.orderBlock {
    margin-top: 10px;
    color: rgb(105, 14, 14);
}

.orderBlock input {
    width: 22px;
    margin-left: 5px;
}

.requiredBlock {
    margin-top: 10px;
}

.buttonOk {
    font-size: 13px;
    margin-right: .5rem;
    margin-top: .5rem;
    background-color: rgb(28, 133, 124) !important;
}

.buttonСancel {
    font-size: 13px;
    margin-left: .5rem;
    background-color: rgba(255, 0, 0, 0.616) !important;
}

.buttonsBlock {
    margin: 1rem 0;
}

.questionType {
    position: absolute;
    bottom: 0;
    right: 5rem;
    font-style: italic;
    font-size: .9rem;
    font-weight: 500;
    color: grey;
}

.moreWordsBlock > div:first-child {
    margin: .5rem 0 .3rem;
}

.moreWordsBlock > div:nth-child(2) {
    color: grey;
}

@media screen and (max-width: 700px) {
    .main {
        width: 100%;
        max-width: 100%;
    }
}