@font-face {
    font-family: 'FuturaFuturisBlackC';
    src: local('FuturaFuturisBlackC'), url('../../fonts/FuturaFuturisBlackC.ttf') format('truetype');
  }

@font-face {
font-family: 'FuturaFuturisCBold';
src: local('FuturaFuturisCBold'), url('../../fonts/FuturaFuturisC-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'FuturaFuturisCBoldItalic';
    src: local('FuturaFuturisCBoldItalic'), url('../../fonts/FuturaFuturisC-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'FuturaFuturisCItalic';
    src: local('FuturaFuturisCItalic'), url('../../fonts/FuturaFuturisC-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'FuturaFuturisC';
    src: local('FuturaFuturisC'), url('../../fonts/FuturaFuturisC.ttf') format('truetype');
}

@font-face {
    font-family: 'FuturaFuturisCameoExtraBoldC';
    src: local('FuturaFuturisCameoExtraBoldC'), url('../../fonts/FuturaFuturisCameoExtraBoldC.ttf') format('truetype');
}

.wrapper {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.content {
    width: 100%;
    background-image: url('../../img/backContent.png');
    background-size: cover;
    height: calc(100vh - 12.5vh);
    background-position: bottom;
    position: relative;
}

.title {
    text-align: center;
    font-size: 8vw;
    padding: 4vw;
    font-family: 'FuturaFuturisC';
    color: #9d0a0e;
    font-weight: 600;
}

.pointsFin {
    font-size: 5vw;
    padding: 2vw 4vw;
    font-family: 'FuturaFuturisC';
    color: #9d0a0e;
    font-weight: 600;
}

.item {
    font-size: 5vw;
    background-color: #fff;
    border-radius: 50px;
    padding: 4vw;
    font-family: 'FuturaFuturisC';
    color: #9d0a0e;
    font-weight: 600;
}

.buttonBack {
    position: absolute;
    bottom: 2vw;
    right: 2vw;
    width: 10vw;
    z-index: 3;
}

.step {
    margin: 2vw;
    width: fit-content;
    border-radius: 100px;
    padding: 3vw 5vw;
    background-color: #9d0a0e;
    color: #fff;
    font-size: 2.5vw;
}

.question {
    color: #9d0a0e;
    margin: 2vw 2vw 8vw 2vw ;
    text-align: center;
    font-size: 4vw;
    font-weight: 500;
}

.timeAndStep {
    display: flex;
    align-items: center;
    justify-content: center;   
}

.final {
    display: flex;
    flex-direction: column;
    margin: 4vw;
}

.pointsTitle {
    text-align: center;
    color: #9d0a0e;
    margin: 2vw;
    text-align: center;
    font-size: 8vw;
    font-weight: 600;
}

.pointsText {
    text-align: center;
    color: #9d0a0e;
    margin: 2vw;
    text-align: center;
    font-size: 6vw;
    font-weight: 500;
}

.imgWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.img {
    width: 50vw;
    background-position: center;
    margin: 1vw auto 2vw;
    height: 29vw;
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
}