@font-face {
    font-family: 'FuturaFuturisBlackC';
    src: local('FuturaFuturisBlackC'), url('./fonts/FuturaFuturisBlackC.ttf') format('truetype');
  }

@font-face {
font-family: 'FuturaFuturisCBold';
src: local('FuturaFuturisCBold'), url('./fonts/FuturaFuturisC-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'FuturaFuturisCBoldItalic';
    src: local('FuturaFuturisCBoldItalic'), url('./fonts/FuturaFuturisC-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'FuturaFuturisCItalic';
    src: local('FuturaFuturisCItalic'), url('./fonts/FuturaFuturisC-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'FuturaFuturisC';
    src: local('FuturaFuturisC'), url('./fonts/FuturaFuturisC.ttf') format('truetype');
}

@font-face {
    font-family: 'FuturaFuturisCameoExtraBoldC';
    src: local('FuturaFuturisCameoExtraBoldC'), url('./fonts/FuturaFuturisCameoExtraBoldC.ttf') format('truetype');
}

.wrapper {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.header {
    height: 12.5vh;
    width: 100%;
    position: relative;
}

.headerInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.headerBg {
    object-fit: cover;
    width: 100vw;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}

.logo {
    z-index: 1;
    position: relative;
    height: 80%;
    display: block;
}

.titleBlock {
    z-index: 1;
    width: 80%;
}

.titleH2 {
    color: #9d0a0e;
    font-weight: 500;
    text-align: center;
    font-family: 'FuturaFuturisC';
    font-size: calc(3vw);
}

.titleH1 {
    color: #9d0a0e;
    font-weight: 600;
    text-align: center;
    font-size: calc(8vw);
    font-family: 'FuturaFuturisC';
}

.doptitle {
    color: #9d0a0e;
    font-weight: 500;
    text-align: center;
    font-family: 'FuturaFuturisC';
    font-size: calc(5vw);
    margin-top: 10vw;
}

.content {
    width: 100%;
    background-image: url('./img/backContent.png');
    background-size: cover;
    height: calc(100vh - 12.5vh);
    background-position: bottom;
}

.contentImg {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem  2rem 0 2rem;
}

.creater {
    height: 50vw;
}

.emblemCreater {
    height: 40vw;
}

.buttonWrapper {
    position: relative;
}

.buttonTitle {
    color: #fff;
    font-family: 'FuturaFuturisC';
    font-weight: 300;
    width: 60%;
    font-size: calc(2.2vw);
    word-break: break-word;
    text-align: center;
    text-transform: uppercase;
}

.button1 {
    background: url('./img/buttonBlue.png');
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36vw;
    height: 34vw;
    background-size: 90% 90%;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: -5vw;
    left: 15vw;
    text-decoration: none;
}

.button2 {
    background: url('./img/buttonPurple.png');
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36vw;
    height: 34vw;
    background-size: 90% 90%;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 12vw;
    left: 39vw;
    text-decoration: none;
}

.button3 {
    background: url('./img/buttonDarkBlue.png');
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36vw;
    height: 34vw;
    background-size: 90% 90%;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 29vw;
    left: 63vw;
    text-decoration: none;
}

.button4 {
    background: url('./img/buttonOrange.png');
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36vw;
    height: 34vw;
    background-size: 90% 90%;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 25vw;
    left: 5vw;
    text-decoration: none;
}

.button5 {
    background: url('./img/buttonYellow.png');
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36vw;
    height: 34vw;
    background-size: 90% 90%;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 42vw;
    left: 29vw;
    text-decoration: none;
}

.button6 {
    background: url('./img/buttonPink.png');
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36vw;
    height: 34vw;
    background-size: 90% 90%;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 59vw;
    left: 53vw;
    text-decoration: none;
}

.button7 {
    background: url('./img/buttonGreen.png');
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36vw;
    height: 34vw;
    background-size: 90% 90%;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 59vw;
    left: 0vw;
    text-decoration: none;
}

.mainWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70%;
    width: 100%;
}

.wrapperButtons {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50vh;
}

.buttonsList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    margin: 0 auto 10% auto;
}

.buttonsList img {
    height: 30vw;
    margin: 0 10vw;
}

.buttonsList {
    text-align: center;
}