.wrapper {
    width: 100%;
    max-width: 1400px;
    box-shadow: 0 0 25px black ;
    border-radius: 4px;
}

.fio {
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: rgba(71, 71, 71, 0.295);
    color: aliceblue;
    font-weight: 800;
    font-size: 54px;
    padding: 70px;
    text-align: center;
    border-bottom: 1px dashed rgba(128, 128, 128, 0.199);
}

.descriptionWrap {
    background-color: rgb(252, 248, 230);
    padding-top: 1rem;
}

.description {
    margin: 0 50px;
}

.personalData, .formData, .documents, .mother, .father, .education, .headSquad, .otherOrganizations, .experience, .sport, .medicine {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100px;
    border-top: 1px dashed grey;
    position: relative;
}

.title {
    margin: -10px;
    color: rgb(126, 5, 5);
    font-weight: 500;
    background-color: rgb(252, 248, 230);
    padding: 0 20px;

}

.personalDataBlock {
    width: 100%;
    margin: 50px 0;
    display: flex;
    justify-content: space-between;
}

.personalDataLeft {
    width: calc(70% - 2rem);
}

.dataRow {
    display: flex;
    position: relative;

    &  + .dataRow {
        margin-top: 30px;
    }

    > .iconsBlock {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        
        > svg {
            cursor: pointer;

            &:hover {
                transform: scale(1.1);
                transition: .2s;
            }
        }
    }


}



.personalDataPhoto {
    /* width: 30%; */
    height: 450px;
    border: 1px dotted grey;
}

.personalDataPhoto img {
    max-width: 100%;
    max-height: 100%;
}

.formDataBlock {
    width: 100%;
    margin: 50px 0;
}

.documentsBlock {
    width: 100%;
    margin: 50px 0;
}

.documentItem {
    max-width: 400px;
    width: 100%;
    height: 300px;
    background-color: rgb(223, 219, 219);
    color: grey;
    display: flex;
    justify-content: center;
    align-items: center;
}

.motherBlock {
    width: 100%;
    margin: 50px 0;
}

.fatherBlock {
    width: 100%;
    margin: 50px 0;
}

.educationBlock {
    width: 100%;
    margin: 50px 0;
}

.headSquadBlock {
    width: 100%;
    margin: 50px 0;
}

.otherOrganizations {

}

.experience {

}

.sportBlock {
    width: 100%;
    margin: 50px 0;
}

.medicineBlock {
    width: 100%;
    margin: 50px 0;
}

.wrapper_buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
}

.buttons {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    justify-content: space-between;
    padding: 25px;
}

.buttonsTop {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0 0 1rem;
}

.buttonsTop button {
    margin: 0 0 0 2rem;
}

.wrapper_buttons button{
    margin: 0 20px;
}

.buttons span {
    margin-left: 10px;
}


.radioSpan {
    padding: 1rem;
    font-weight: 500;
    color:rgb(2, 2, 114);
    font-size: 18px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.radioSpan span {
    padding-left: .5rem;
}

.wrapperSliderEquipment {
    width: 100%;
    max-width: 300px;
  }
  
  .equipment__small .photoWrapper {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
  
  
  .deletePhoto {
    position: absolute;
    right: 0;
    top: 0;
    color: red;
    font-size: 28px;
    z-index: 9;
  }

  .search {
    position: absolute;
    right: 40px;
    top: 0;
    color: #75c2b5;
    font-size: 28px;
    z-index: 9;
  }
  
  .wrapperSliderEquipmentBig img {
    height: 300px;
    object-fit: contain;
  }
  
  .wrapperSliderEquipmentSmall img {
    height: 100px;
    object-fit: contain;
  }

.bg {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    background-color: rgba(128, 128, 128, .9);
}

.popup {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup img {
    height: 100%;
    max-height: 80vh;
    width: 90vw;
    max-width: 1000px;
    object-fit: contain;
}

.popup_close {
    color: #cc0606;
    font-size: 2rem;
    padding: 1rem;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 99999;
}

.fileModalButton {
    width: 70px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #ced4da;
    border-radius: .5rem;
    cursor: pointer;
    
    &:hover {
        border-style: solid;
    }
}

.smallFileModalButton {
    width: 50px;
    height: 50px;
    margin-bottom: 2rem;
}

.modal {

    width: 100%;
    min-width: 600px;

    > * + * {
        margin-top: 1rem;
    }

    > h3 {
        color: #6a1919;
        text-align: center;
    }

    .filesBlock {
        
        > p {
            font-size: .9rem;
            font-weight: 500;
            color: rgb(83, 1, 1);
        }
        
        > ul {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .buttonsBlock {
        display: flex;
        justify-content: center;

        & button + button {
            margin-left: 1rem;
        }
    }

}


/* __________________SLIDER_________________ */
  
  .sliderWrapper {
    width: 300px;
    margin: 0 auto;
  }

  @media (max-width: 1024px) {
    .personalDataLeft {
        width: 100%;
        flex-direction: column;
    }

    .personalDataBlock {
        flex-wrap: wrap;
        justify-content: center;
    }

    .sliderWrapper {
        width: 250px;
        margin: 1rem auto;
      }

      .description {
        margin: 0 1rem;
    }

    .dataRow {
        display: flex;
        flex-wrap: wrap;
    }

    .buttons {
        justify-content: center;
        flex-wrap: wrap;
    }

    .buttons button {
        margin-bottom: 1rem;
    }

    .documentItem {
        margin-bottom: 1rem;
    }

    .fio {
        font-size: 1.5rem;
        padding: 1rem;
        word-break: break-all;
    }
  }

  .createAndApdateTime {
      display: flex;
      flex-direction: column;
  }

  .createAndApdateTime span {
    font-size: 15px;
    margin-bottom: .5rem;
    color: rgb(148, 142, 134);
    display: flex;
}

  .createAndApdateTime span div:nth-child(odd) {
    width: 350px;      
  }

  .createAndApdateTime time, .createAndApdateTime cite {
    color: rgb(126, 5, 5);
    font-weight: 500;
  }

  .createAndApdateTime time {
    margin-right: 2rem;
  }

  .inputWrapper {
    border-bottom: 1px dotted grey;
    margin: 0 10px;
    width: 100%;
    max-width: 380px;
    position: relative;
    padding: 2px;
}

.title {
    font-size: 12px;
    color: rgb(148, 142, 134);
}

.input {
    margin: 10px 0;
    width: 100%;
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 18px;
    font-weight: 500;
    font-style: italic;
    color: rgb(2, 2, 114);
}


.block {
    margin: 10px 0;
    width: 100%;
    min-height: 20px;
    font-size: 18px;
    font-weight: 500;
    /* font-style: italic; */
    color: rgb(2, 2, 114);
}


@media (max-width: 1024px) {
    .inputWrapper {
        margin: 1rem 0;
    }
}

.pickerProfile  {
    margin: 10px 0;
    width: 100%;
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 18px;
    font-weight: 500;
    font-style: italic;
    color: rgb(2, 2, 114);
    user-select: none;
}

.blockTroop {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.blockTroop i {
    width: 1.6rem;
    height: 1.6rem;
    font-size: 1.6rem;
    margin-left: .2rem;
    cursor: pointer;
}

.titleTroop {
    font-size: 12px;
    color: rgb(148, 142, 134);
}

.historyBlock {
    background-color: rgb(248, 239, 228);
    border-radius: 5px;
    border: 1px solid rgb(233, 221, 221);
    box-shadow: 3px 3px 5px rgb(185 181 181);
    display: block;
    padding: 1rem 0 0 0;
    position: absolute;
    width: 100%;
    top: 5rem;
    right: 0;
    z-index: 1;
}

.troopPopup {
    position: absolute;
    width: 100%;
    top: 5rem;
    right: 0;
    z-index: 1;
    max-height: 325px;
    background-color: rgb(248, 239, 228);
    border-radius: 5px;
    border: 1px solid rgb(233, 221, 221);
    padding: 1rem 0 0 0;
    box-shadow: 3px 3px 5px rgb(185 181 181);
    display: block;
}

.troopListBlock {
    max-height: 280px;
    overflow-y: auto;
}

.troop_active {
    background-color: rgb(233, 197, 152);
    border: 1px solid rgb(233, 221, 221);
    box-shadow: 3px 3px 5px rgb(185 181 181);
    color: #9d0a0e;
    font-size: 14px;
    padding: .5rem 1rem;
    cursor: pointer;
}

.troopListName:hover {
    background-color: rgb(233, 197, 152);
    color: #9d0a0e;
    cursor: pointer;
    transition: .4s;
    cursor: pointer;
}

.historyName, .troopListName {
    background-color: rgb(248, 239, 228);
    border: 1px solid rgb(233, 221, 221);
    box-shadow: 3px 3px 5px rgb(185 181 181);
    font-size: 14px;
    padding: .5rem 1rem;
    list-style: none;
}

.historyName > span:last-child {
    font-style: italic;
    font-size: 14px;
}

.titleHistory {
    color: #9d0a0e;
    margin-bottom: .5rem;
    display: block;
    padding: 0 0 0 1rem;
}

.closeHistory {
    position: absolute;
    right: 0rem;
    top: 0rem;
    font-size: 2rem;
    height: 2rem;
    width: 2rem;
    cursor: pointer;
    z-index: 2;
}

.gtoWrapper::-webkit-scrollbar {
    height: .5rem;
}

.blockTroop::-webkit-scrollbar {
    width: .5rem;
  }
  
.blockTroop::-webkit-scrollbar-track {
    background-color: #eee;
    border-radius: 1rem;
}

.blockTroop::-webkit-scrollbar-thumb {
    background-color: #9d0a0e;
    border-radius: 1rem;
}

.troopListBlock::-webkit-scrollbar {
    width: .5rem;
  }
  
.troopListBlock::-webkit-scrollbar-track {
    background-color: #eee;
    border-radius: 1rem;
}

.troopListBlock::-webkit-scrollbar-thumb {
    background-color: #9d0a0e;
    border-radius: 1rem;
}

.inputSearch {
    width: 50%;
    outline: none;
    border-radius: .2rem;
    border: 3px solid #9d0a0e;
    font-weight: 500;
    margin: 0 0 0 .5rem;
    padding: .2rem;
}

.gtoWrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
    width: 100%;
}

.rowWrapAward {
    display: flex;
    flex-wrap: wrap;
    width:  100%;
}

.awardItem {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 1.5rem;
    width: 100%;
}

.trashIcon {
    cursor: pointer;
    color: red;
    margin-left: 1rem;
    font-size: 2rem;
    margin-top: 1.3rem;
}

.rowWrap {
    margin: 0 2rem 0 0;
}

.rowWrap > * {
    margin: 0 0 1rem 0;
}

.medalIcon {
    width: 2rem;
    height: 2rem;
    font-size: 2rem;
    cursor: pointer;
    color: rgb(2, 2, 114);
    display: block;
    margin-top: .5rem;
}

.titleDoc {
    font-size: 12px;
    color: rgb(148, 142, 134);
}

.sportBlockDoc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 73px;
}

.sportBlockSecond {
    width: 100%;
    margin: 0 auto 50px;
}

.iconsBlock {
    min-width: fit-content;
}

.docTable {
    margin-left: 100px;
    padding-bottom: 50px;
}

.docTable table {
    border-collapse: collapse;
}

.docTable th, .docTable td {
    border: 1px solid rgb(173, 171, 171);
    padding: 1px 5px;
    color: rgb(97, 96, 96);
    background-color: rgba(255, 255, 255, 0.74);
}

@media screen and (max-width: 622px) {
    .buttonsTop {
        flex-direction: column;
        align-items: center;
    }
    
    .buttonsTop button {
        margin: 0 0 .5rem 0;
        max-width: 80%;
    }   

    .createAndApdateTime span {
        flex-direction: column;
    }

    .createAndApdateTime span div:nth-child(odd), .createAndApdateTime span div:nth-child(even) {
        width: 100%;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 24px;
    margin: 0 0 0 auto;
  }
  
  .switch input {
    display:none;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
  }
  
  .switch input:disabled + .slider {
    opacity: 0.5;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: brown;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px brown;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(34px);
    -ms-transform: translateX(34px);
    transform: translateX(34px);
  }

  .awardInput {
    width: 100%;
    max-width: 29%;
    min-width: 330px;
    margin: 0.5rem
}

  .awardLabel {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.removeButton {
    margin: 0 0 .5rem 1rem;
    font-size: .8rem;
}

@media screen and (max-width: 1024px) {
    .awardLabel {
       height: auto;
    }   
}