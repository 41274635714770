.container {
  max-width: 1100px;
  width: 100%;
}

.title {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  background: linear-gradient(45deg, rgb(185 26 26) 0%, rgb(253 196 126) 100%);
  padding: 0 16px;
  color: #ffffff;
  font-size: 23px;
  font-weight: 400;
}

.newsList {
  width: 100%;
  min-height: 523px;
  height: calc(100% - 34px);
  padding: 10px 14px 70px;
  background-color: #fff;
  position: relative;
}

.newsListHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 10px 15px 10px 4px; */
}

.tableHeader {
  display: flex;
  align-items: center;
  min-height: 36px;
  border-bottom: 1px solid #c4c4c4;
}



.tableHeader div:first-child {
  color: #4c4c4c;
  font-size: 15px;
  font-weight: 700;
}

.table {
  max-height: 500px;
  overflow: auto;
  user-select: none;
}

.tableRow {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d8d5d5;
}

.tableRow:hover {
  background-color: #fcf6f6b4;
}

.tableRow:hover .displayNameFirst {
  color: rgb(16, 86, 80);
  font-weight: 500;
}

.displayname {
  color: #5b5c61;
  cursor: pointer;
  flex-basis: 80%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.iconPlus {
  color: rgb(34, 141, 132);
  cursor: pointer;
}

.iconDelete {
  color: red;
  cursor: pointer;
}

.displayname span {
  position: relative;
  margin-left: 5px;
}

.displayname span::after {
  position: absolute;
  bottom: 5px;
  left: 0;
  content: "";
  height: 1px;
  width: 100%;
  background-color: rgb(75, 112, 109);
  opacity: 0;
  transition: all 0.2s ease-in;
}

.displayname:hover span {
  color: rgb(16, 86, 80);
  font-weight: 500;
}

.displayname:hover span::after {
  opacity: 1;
  transform: translateY(5px);
}

.superuser {
  flex-basis: 10%;
}

.edit {
  flex-basis: 3%;
}

.edit svg {
  color: rgb(16, 86, 80);
  cursor: pointer;
  font-size: 24px;
}

.actions {
  flex-basis: 5%;
}

.actions svg {
  color: rgb(204, 20, 20);
  cursor: pointer;
  font-size: 24px;
}

.actions svg:hover {
  color: #ff0000;
}

.deleteIcon {
  width: 20px;
  cursor: pointer;
}

.pagination {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
}

.closeBtn {
  display: none;
}

.modal {
  max-width: 920px;
  width: 100%;
  height: auto;
  padding: 0;
  background-color: #fff;
  overflow: hidden;
}

.modalBody {
  width: 100%;
  padding: 15px 15px 30px;
}

.dateTitle {
  font-size: .9rem;
  font-weight: 500;
  color: rgb(83, 1, 1);
  margin-bottom: 1px;
}

.dataBlock {
  font-size: 20px;
}

.dateBlock input {
  border-radius: 5px;
  border: 1px solid rgb(139, 138, 138);
  padding: 1px 0 1px 3px;
  font-size: 18px;
  font-weight: 400;
  width: 100px;
}

.inputsBlock>div {
  margin-top: 8px;
}

.modalMain {}

.input {
  width: 90%;
  font-size: 18px;
  font-weight: 500;
}

.select {
  width: 90%;
  font-size: 18px;
  font-weight: 500;
}

.filesBlock {
  margin-top: 15px;
  width: 90%;
  word-break: break-all;
}

.fileItem {
  display: flex;
  padding-left: 10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px dashed rgb(119, 118, 118);
}

.fileItem:hover {
  border: 1px solid rgb(119, 118, 118);
}

.fileItem a {
  width: 90%;
}

.fileItem+.fileItem {
  margin-top: 5px;
}

.imgBlock {
  margin: 4px 10px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.imgBlock img {
  max-width: 100%;
  max-height: 100%;
}

.input {
  margin-top: 10px;
}

.modalButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.modalButtons button {
  width: 125px;
  height: 30px;
  margin: 0 20px;
  font-size: 16px;
  border-radius: 25px;
}


.pdf {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: 1rem 0;
  padding: .5rem;
  border: 1px solid rgba(0, 0, 0, .5);
  border-radius: .2rem;
}

.imagePDF {
  color: red;
  font-size: 30px;
  height: 30px;
  width: 30px;
  margin: 0 .5rem 0 0;
}

.titlePDF {
  font-size: 16px;
}

.linkpdf {
  display: contents;
  color: #000;
}

.toTrash {
  color: red;
  font-size: 25px;
  width: 25px;
  height: 25px;
  margin: 0 0 0 3rem;
  cursor: pointer;
}

.paramsBlock {
  border: 1px solid rgb(167, 166, 166);
  border-radius: 10px;
  padding: 10px;
  color: rgb(83, 1, 1);
  position: relative;
}

.paramsBlock>button {
  background: linear-gradient(45deg, rgb(185 26 26) 0%, rgb(253 196 126) 100%);
  border-radius: 5px;
  padding: 7px;
  color: white;
  transition: all 0.2s ease-in;
}

.paramsBlock>button:hover {
  opacity: 0.8;
}

.paramsTitle {
  font-weight: 500;
  color: rgb(75, 74, 74);
  margin: -4px 0 4px 0;
}

.paramsList {
  border-bottom: 1px solid rgb(167, 166, 166);
  padding-bottom: 10px;
  margin-bottom: 10px;
  max-height: 500px;
  overflow: auto;
}

.paramsItem {
  border: 1px solid grey;
  background-color: #c9c9ff;
  border-radius: 10px;
  padding: 3px 25px 3px 3px;
  margin: 10px 5px;

  position: relative;
  box-shadow: 0 0 2px grey;
}

.paramsItem svg:first-child {
  position: absolute;
  top: 2px;
  right: 2px;
  font-size: 20px;
  cursor: pointer;
}

.paramsItem svg:nth-child(2) {
  position: absolute;
  top: 20px;
  right: 2px;
  font-size: 16px;
  cursor: pointer;
}




.paramsItem span {
  font-size: 14px;
  margin-right: 3px;
}

.firstRow {
  display: flex;
  align-items: flex-start;
}

.firstRow span {
  display: inline-block;
  max-width: 105px;
  width: 100%;
  text-align: right;
}

.firstRow textarea {
  width: 100%;
  padding-left: 2px;
  border: 1px solid rgb(161, 160, 160);
}

.secondRow {
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
}

.secondRow div {
  display: flex;
}


.secondRow div:first-child span {
  display: inline-block;
  width: 100%;
  max-width: 105px;
  min-width: 105px;
  text-align: right;
}

.secondRow div:first-child select {
  min-width: 250px;
  width: 100%;
  border: 1px solid rgb(161, 160, 160);
}


.secondRow div:last-child span {
  max-width: 155px;
  width: 100%;
}

.secondRow div:last-child input {
  width: 100%;
  max-width: 40px;
  padding-left: 2px;
  border: 1px solid rgb(161, 160, 160);
}


.paramsItem button {
  background-color: white;
  padding: 2px;
  margin: 3px;
  border-radius: 3px;
  border: 1px solid grey;
  color: rgb(82, 81, 81);
  transition: all 0.2s ease-in;
}

.paramsItem button:hover {
  color: rgb(107, 24, 24);
}

.requiredCheckbox {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.displayNameFirst {
  color: #5b5c61;
  cursor: pointer;
  flex-basis: 20%;
  line-break: anywhere;
}

.displayDescFirst {
  color: #5b5c61;
  cursor: pointer;
  flex-basis: 80%;
  line-break: anywhere;
}

.modalAdd {
  width: 100%;
  max-width: 500px;
  padding: 0;
}

.modalAddBody {
  margin: 1rem;
}

.validate {
  font-size: .9rem;
  font-weight: 500;
  color: red;
  margin-bottom: 1px;
}

.clickBlock {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 5px 0;
}

.paramsBlocks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.buttonBlock {
  display: flex;
}

.criterionsModal {

  > h2 {
    color: #873030;
    text-align: center;
    margin-bottom: 1rem;
  }

  > ul {
    list-style: none;

    > li {

      > div:first-child {
        background-color: rgba(0, 0, 255, 0.21);;
        color: white;
        cursor: pointer;
        display: flex;

        > p {
          padding: .25rem;
          width: calc(100% - 1.5rem);
        }

      }
      
    }
    
    ul {
      list-style: none;
      padding-left: 1rem;
      
      > li {
        > div:first-child {
            cursor: pointer;
            color: #5b5c61;
            display: flex;
          > p {
            padding: .25rem;
            width: calc(100% - 1.5rem);
          }
        }
      }
    }
  }
}

.buttonsBlock {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: .5rem;
}

@media (max-width: 425px) {
  .title {
    font-size: 1rem;
  }

  .pagination {
    width: calc(100% - 2rem);
  }
}