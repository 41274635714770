.container {
  /* height: 500px; */
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
}



.btn_grey {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 20px;
  border-radius: 9px;
  border: none;
  background-color: #cc0606;
  color: #fffefe;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.btn_grey:disabled {
  opacity: 0.7;
}

/*==============================ERA NEWS CREATE===================================*/
.addNews {
  margin: 0 auto;
  max-width: 1100px;
  min-height: calc(100% - 56px);
  width: 100%;
}

.addNews__container {
  height: auto;
  background: #fff;
}

.addNewsBody {
  height: calc(100% - 34px);
  display: flex;
  justify-content: space-between;
  padding: 15px 15px 30px;
  min-height: 423px;
  background: white;
  margin: 0 1rem;
}

.addNewsBody__left {
  width: 100%;
  max-width: 781px;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 15px;
}

.addNewsBody__right {
  width: 200px;
}

.news-title .input {
  padding: 0;
}

.news-title div {
  display: flex !important;
  flex-direction: column;
}

.news__label {
  color: #989898;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 5px;
}

.newsTitle {
  width: 100%;
  height: 24px;
  border: 1px solid #cecece;
  background-color: #ffffff;
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  padding: 3px 6px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  resize: none;
  margin-bottom: 19px;
}

.newsText {
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 20px;
}

.newsText .ql-container {
  height: calc(100% - 622px);
  min-height: 293px;
}

.newsText2 {
  min-height: 100px !important;
  border-color: #d4cdcd;
  resize: none;
}

.news-left-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

.news-left-btn {
  width: 133px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: none;
  background-color: #d81717;
  margin: 0 23px;
  color: #ffffff;
  font-family: Calibri;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.news-right-foto {
  width: 100%;
  height: 161px;
  border: 1px solid #cecece;
  background-color: #ffffff;
  margin-bottom: 40px;
  position: relative;
}

.news-fotoBlock img {
  width: 100%;
  max-height: 133px;
}

.news-fotoBlock label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 115px;
  height: 27px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  border: none;
  border-radius: 20px;
  background-color: #999999;
  color: #fffefe;
  font-size: 10px;
  font-weight: 300;
  cursor: pointer;
}

.news-fotoBlock label input {
  display: none;
}

.news-right-foto__btns {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -30px;
}

.news-right-foto__btns button {
  width: 93px;
  height: 18px;
  border: none;
  border-radius: 9px;
  background-color: #d81717;
  color: #fffefe;
  font-size: 10px;
  font-weight: 300;
  text-transform: uppercase;
  cursor: pointer;
}

.news-source,
.news-linkSource {
  width: 100%;
  height: 24px;
  border: 1px solid #cecece;
  background-color: #ffffff;
  padding: 0px 7px;
  color: #000000;
  font-size: 15px;
  font-weight: 400;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  resize: none;
  margin-bottom: 10px;
}

.addNewsBody__right .news-datepicker input, .addNewsBody__left .news-datepicker input {
  width: 200px;
  height: 24px;
  border: 1px solid #cecece;
  background-color: #ffffff;
  padding: 0px 7px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #000000;
  font-family: 'Proxima Nova';
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
  background: url('../../assets/images/news-datepicker-arrow.png') 97% center no-repeat;
  cursor: pointer;
}

.news__label_mt {
  margin-top: 10px;
}

.react-datepicker-popper {
  z-index: 10;
}

/*==============================ERA NEWS CREATE===================================*/

/*==============================ERA NEWS LIST===================================*/
.newsListContainer {
  position: relative;
}

.newsListContainer__title {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  background: linear-gradient(45deg, rgb(185 26 26) 0%, rgb(253 196 126) 100%);
  padding: 0 16px;
  color: #ffffff;
  font-size: 23px;
  font-weight: 400;
}

.newsList {
  width: 100%;
  min-height: 523px;
  height: calc(100% - 34px);
  padding: 0px 14px 70px;
  background-color: #fff;
  position: relative;
}

.newsListHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px 10px 4px;
}

.newsListHeader__btn {
  margin: .5rem .5rem .5rem 0;
}

.taskListHeader__btn {
  width: 125px;
  height: 30px;
  margin: 15px 0 10px;
  font-size: 16px;
  border-radius: 25px;
}

.newsListHeader__dropdown .Dropdown-control {
  width: 190px;
  height: 20px;
  margin: .5rem;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #c6c5c5;
  background-color: #ffffff;
  padding: 0;
  padding-left: 13px;
  color: black;
  font-size: 14px;
  font-weight: 400;
}

.infoterminal .Dropdown-menu {
  width:230px;
}
.infoterminal .Dropdown-control{
  border-radius: 0;
  margin-bottom: 20px;
  width:230px;
}

.newsListHeader__dropdown .Dropdown-placeholder {
  color: #b6b6b6;
}

.newsListHeader__dropdown .Dropdown-placeholder.is-selected {
  color: black;
  white-space: nowrap;
  width: 83%;
  overflow: hidden;
}

.newsListHeader__dropdown .Dropdown-menu {
  top: 120%;
}

.newsListHeader__dropdown .arrow-closed {
  width: 11px;
  height: 6px;
  background: url('../../assets/images/dropdown-arrow-icon.png') center center no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  border: none;
  top: 7px;
}

.newsListHeader__input {
  display: flex;
  align-items: center;
}

.newsListHeader__input input {
  margin: .5rem;
  width: 412px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #c6c5c5;
  background-color: #ffffff;
  padding: 0 13px;
}

.newsListHeader__input button {
  width: 22px;
  height: 21px;
  margin-left: 8px;
  border: none;
  background: url('../../assets/images/lupa.png') center center no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}

.newsListRow {
  display: flex;
  align-items: center;
  min-height: 36px;
  border-bottom: 1px solid #c4c4c4;
}

.newsListRow__header {
  border-top: 1px solid #c4c4c4;
}

.newsListRow__header div {
  color: #4c4c4c;
  font-size: 15px;
  font-weight: 700;
}

.newsListRow__header .newsListRow__date {
  padding-left: 15px;
}

.newsListRow__state {
  max-width: 135px;
  width: 100%;
  position: relative;
}

.newsListRow__title {
  max-width: 430px;
  width: 100%;
  color: #4c4c4c;
  font-size: 14px;
  font-weight: 400;
  padding: 2px 10px 0 2px;
}

.newsListRow__title a {
  color: #5871c5;
  text-decoration: none;
}

.newsListRow__author {
  max-width: 265px;
  width: 100%;
  color: #4c4c4c;
  font-size: 14px;
  font-weight: 400;
}

.newsListRow__date {
  flex-grow: 1;
  color: #4c4c4c;
  font-size: 14px;
  font-weight: 400;
}

.newsListTable {
  width: 100%;
  /* height: calc(100% - 74px); */
  /* overflow: auto; */
}

.newsListRow__stateBtns {
  display: flex;
  width: 96px;
  height: 21px;
  border-radius: 15px;
  border: 1px solid #c4c4c4;
}

.newsListRow__stateBtn {
  width: 31px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  padding: 0;
}

.newsListRow__stateBtn_check img {
  width: 15px;
  height: 14px;
  margin: auto;
}

.newsListRow__stateBtn_star {
  border-left: 1px solid #c4c4c4;
  border-right: 1px solid #c4c4c4;
}

.newsListRow__stateBtn_star img {
  width: 13px;
  height: 14px;
  margin: auto;
}

.newsListRow__stateBtn_drop img {
  width: 11px;
  height: 6px;
  margin: auto;
}

.newsListTableDropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 24px;
  left: 29px;
  width: 110%;
  height: auto;
  border: 1px solid #c6c5c5;
  background-color: #ffffff;
  z-index: 10;
}

.newsListTableDropdown div div {
  display: flex;
  align-items: center;
  width: 100%;
  height: 27px;
  border: none;
  background-color: transparent;
  color: #505050;
  font-size: 14px;
  font-weight: 400;
  padding-left: 10px;
  cursor: pointer;
}

.newsListTableDropdown div img {
  width: 16px;
  height: auto;
  margin-right: 13px;
}

.newsListTableDropdown__btnTrash img {
  object-fit: contain;
  width: 15px !important;
  height: 15px !important;
  margin-right: 15px !important;
}

.newsListHeader__dropdown .Dropdown-option {
  color: #505050;
  font-size: 14px;
  font-weight: 400;
}


/*==============================PAGINATION===================================*/
.newsListPagination {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
}



.controls {
  position: absolute;
  bottom: 25px;
  left: 25px;
  display: flex;
  align-items: center;
}

.sortBlock {
  border: 1px solid rgb(187, 184, 184);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.sortBlock:active {
  background-color: rgb(250, 226, 192);
}

.sortActive {
  background-color: rgb(208, 253, 238);
  border-color: rgb(148, 146, 146);
}

.sortBlock svg {
  font-size: 20px;
  color: rgb(187, 184, 184);
}

.sortActive svg {
  color: rgb(148, 146, 146);
}

.sortBlock:hover {
  transform: scale(1.05);
}

.itemsCount {
  margin-left: 10px;
  user-select: none;
}

.itemsCount select {
  color: grey;
  cursor: pointer;
  outline: none;
  border-radius: 3px;
}

.itemsCount span {
  margin-left: 5px;
  color: grey;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.pagination .previous {
  margin-right: 15px;
}

.pagination .next {
  margin-left: 15px;
}

.pagination li {
  height: 20px;
  min-width: 20px;
  list-style-type: none;
}

.pagination li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #989898;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  text-decoration: none;
  cursor: pointer;
}

.pagination li.active a {
  color: #d24a43;
}

/*==============================USERS===================================*/


.userListRow__displayname {
  flex-basis: 40%;
}

.userListRow__username {
  flex-basis: 30%;
}

.userListRow__superuser {
  flex-basis: 20%;
}

.userListRow__actions {
  flex-basis: 10%;
}

.deletefile-icon {
  width: 20px;
  cursor: pointer;
}

.deletefile-icon.deleting {
  opacity: 0.5;
}


/*==============================ADD_USERS-MODAL===================================*/


.display-none {
  display: none;
}

.modalBody {
  width: 100%;
  padding: 14px;
  background-color: #fff;
}

.modal-container {
  padding: 0;
}

.fio {
  color: #5871c5;
  cursor: pointer;
}

.green{
  color: green
}

.red{
  color: red
}

.adduserform-input {
  display: block;
  margin-bottom: 5px;
  width: 100%;
}

.adduserform-input-group {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.adduserform-input-group > div {
  width: 100%;
}

.upload-file-btn {
  position: relative;
}

.upload-file-btn > div {
  position: absolute;
  right: -50px;
}

.settingModal {
  max-width: 960px;
  width: 100%;
  height: auto;
}

.settingModal_new {
  max-width: 800px;
  width: 100%;
  height: auto;
}

.settingModalBody {
  padding: 15px 15px 30px;
}

.settingModal__label {
  display: block;
  font-size: 13px;
}

.settingModal__input {
  margin-bottom: 20px;
}

.settingModal__inputArea {
  height: 80px
}

.settingModal__checkbox > div {
  margin: 5px 0 0 0;
}


.settingModal__labelCheck {
  margin: 0;
  font-size: 13px;
  margin-right: 10px;
}

.settingModal__group {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.settingModal__group > div {
  flex-grow: 1;
}

.settingModal__btnChange {
  height: 23px;
  border-radius: 12px;
  margin-left: 25px;
  margin-bottom: 21px;
  font-size: 13px;
}

.settingModal__btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}

.settingModal__btns button {
  width: 125px;
  height: 30px;
  margin: 0 20px;
  font-size: 16px;
  border-radius: 25px;
}

.materials_input {
  width: 350px;
  font-size: 18px;
  font-weight: 500;

}

@media (max-width: 980px) {
  .container {
    margin: 0 auto;
  }
  .newsListHeader__input input {
    width: 140px;
  }
}

@media (max-width: 768px) {

  .itemsCount {
    position: static;
    bottom: auto;
    left: auto;
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container {
    width: 100%;
    max-width: calc(100%);
    height: auto;
    display: block;
  }
  .newsListHeader {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .newsListHeader__input input {
    max-width: 240px;
    width: 100%;
  }
  .newsListContainer {
    height: auto;
  }
  .newsListContainer__title {
    font-size: 1rem;
  }
  .newsList {
    min-height: 0;
  }
  .addNewsBody {
    width: calc(100% - 2rem);
    display: block;
  }
  .addNewsBody__left {
    max-width: 340px;
    width: 100%;
  }
  .newsListRow__author {
    display: none;
  }
  .newsListRow {
    word-break: break-all;
    font-size: 15px;
  }
}

.regionWrapper {
  margin: 1rem 0;
  min-height: 28vh;
  height: 28vh;
  overflow-y: scroll; 
  overflow-x: hidden;
}

.regionWrapper::-webkit-scrollbar {
  background-color: #eee;
  width: .5rem;
}

.regionWrapper::-webkit-scrollbar-thumb {
  background-color: rgb(95, 95, 95);
  border-radius: 1rem;
}

.regionTitle {
  margin: 1.5rem 0 .5rem;
  display: block;
  color: #989898;
  font-size: 13px;
  font-weight: 300;
}
