.wrapper {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrapper h1 {
    color: rgb(116, 11, 11);
    text-align: center;
}

.buttons {
    margin-top: 100px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
}

.buttons div {
    cursor: pointer;
    width: 250px;
    height: 250px;
    border-radius: 20px;
    background-color: rgb(168, 168, 168);
    font-size: 40px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 3px rgb(173, 119, 38);
    margin: 5px;
}

.buttons div:hover {
    background-color: rgb(83, 83, 83);
    transition: all ease-in 0.3s;
    box-shadow: 0 0 20px rgb(173, 119, 38);
    transform: scale(1.1);
}

.buttons div:active {
    background-color: rgb(176, 0, 2);
    color: rgb(255, 255, 255);
    transition: none;
}