@font-face {
    font-family: 'FuturaFuturisBlackC';
    src: local('FuturaFuturisBlackC'), url('../fonts/FuturaFuturisBlackC.ttf') format('truetype');
  }

@font-face {
font-family: 'FuturaFuturisCBold';
src: local('FuturaFuturisCBold'), url('../fonts/FuturaFuturisC-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'FuturaFuturisCBoldItalic';
    src: local('FuturaFuturisCBoldItalic'), url('../fonts/FuturaFuturisC-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'FuturaFuturisCItalic';
    src: local('FuturaFuturisCItalic'), url('../fonts/FuturaFuturisC-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'FuturaFuturisC';
    src: local('FuturaFuturisC'), url('../fonts/FuturaFuturisC.ttf') format('truetype');
}

@font-face {
    font-family: 'FuturaFuturisCameoExtraBoldC';
    src: local('FuturaFuturisCameoExtraBoldC'), url('../fonts/FuturaFuturisCameoExtraBoldC.ttf') format('truetype');
}

/* .wrapper {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.header {
    height: 12.5vh;
    width: 100%;
    position: relative;
    overflow: hidden;
} */

/* .content {
    width: 100%;
    background-image: url('../panel/backContent.png');
    background-size: cover;
    height: calc(100vh - 12.5vh);
    background-position: bottom;
} */

.wrapper {
    padding-top: 3vw;
    width: 100%;
    background-image: url('../img/backContent.png');
    background-size: cover;
    height: calc(100vh - 12.5vh);
    background-position: bottom;
}

.innerWrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

.wrapperButton {
    width: 30vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2vw;
}

.img {
    width: 70%;

}

.name {
    color: #700000;
    font-weight: 600;
    text-align: center;
    font-size: calc(2vw);
    font-family: 'FuturaFuturisC';
}

.buttonBack {
    position: absolute;
    bottom: 2vw;
    right: 2vw;
    width: 10vw;
}

.wrapperVideo {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,.7);
    z-index: 1;
}

.video {
    width: 90vw;
    margin: 0 auto;
    display: block;
    position: relative;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 5;
}
