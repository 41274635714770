.dailyEventWrapper {
  font-size: 0.8em;
  min-height: 1.6em;
  left: 50px;
  height: 100%;
  color: white;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 5px 10px;
  line-height: 100%;
}

.dailyEventTitle {
  font-weight: 600;
}
