
.content {
    width: 100%;
    min-height: 800px;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 20px;
    user-select: none;
}

.content img {
    pointer-events: none;
}

.content div, .content span {
    font-family: 'MyFont';
    color: #004971;
}

.block1, .block3, .block4, .block5, .block6, .block7 {
    font-family: 'MyFont';
    background-color: rgb(248, 239, 228);
    border-radius: 5px;
    border: 1px solid rgb(233, 221, 221);
    box-shadow: 3px 3px 14px rgb(185, 181, 181);
    padding: 30px;
}

.block1 {
    grid-column: 1 / 3;
    grid-row: 1 / 10;
    display: grid;
    grid-template-rows: repeat(10, 1fr);
    gap: 3px;
}

.block1_1 {
    grid-row: 1 / 3;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 20px;

}

.block1_1_number_big {
    font-size: 80px;
    font-weight: 600;
}

.block1_1_number {
    font-size: 40px;
    font-weight: 600;
}

.block1_1_text_big {
    font-size: 1.3rem;
    font-weight: 600;
}

.block1_1_text {
    margin-left: 10px;
    font-size: 1.2rem;
    font-weight: 600;
}

.block1_numbers {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}

.block1_texts {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;    
}

.block3_texts {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}

.block3_digitals {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 1.2rem;
}

.block1_2 {
    grid-row: 3 / 5;
    display: flex;
}

.block1_2_image {
    /* max-height: 100px; */
    width: 40%;
}

.block1_2_text {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 5px;
}

.block1_2_text div:first-child {
    font-size: 20px;
    color: #9d0a0e;
}

.block1_2_text div:last-child {
    margin-top: 50px;
}

.block1_3 {
    grid-row: 5 / 10;
    border-top: 2px solid rgb(0, 85, 81);
    border-left: 2px solid rgb(0, 85, 81);
    display: grid;
    grid-template-rows: repeat(10, 1fr);
    gap: 2px;
    padding-left: .5rem;
}

.block1_3_1 {
    grid-row: 1 / 3;
    font-size: 34px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.block1_3_2 {
    grid-row: 3 / 6;
    display: flex;
    align-items: center;
}

.block1_3_2_text {
    margin-left: 10px;
    font-size: 24px;
}

.block1_3_2_text span {
    font-weight: 800;
    font-size: 28px;
}

.block1_3_2descr {
    font-size: 16px;
}

.block1_3_2descr span {
    font-weight: 800;
    font-size: 20px;
}

.block1_3_3 {
    grid-row: 6 / 11;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    height: 200px;
}

.block1_3_3 img {
    max-height: 150px;
}

.block1_4 {
    grid-row: 10 / 11;
}

.block1_4 > div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.block1_4_text {
    font-size: 17px;
    color: #9d0a0e !important;
    margin-top: 5px;
}

.block1_4_number {
    font-size: 30px;
    font-weight: 700;
    color: #9d0a0e !important;
}

.block2 {
    grid-column: 3 / 7;
    grid-row: 1 / 2;
    font-size: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    flex-direction: column;
    padding: 30px;
}

.block2 > * {
    color: #9d0a0e !important;
    text-decoration: underline;
    text-decoration-skip-ink: none;
}

.block3 {
    display: flex;
    align-items: center;
}

.block3 > div:first-child {
    width: 45%;
    padding: 5px;
}

.block3 > div:last-child {
    width: 47%;
    padding: 5px;
    margin: 0 0 0 auto;
}


/* .block3_text span {
    font-family: 'MyFont';
    font-size: 24px;
    color: #004971;
}

.block3_text span:first-child {
    display: inline-block;
    min-width: 210px;
    text-align: right;
}

.block3_text span:nth-child(2) {
    padding: 0 5px;
    font-size: 26px;
    font-weight: 800;
} */

.block3_digitals_1 {
    font-weight: 600;
}

.block3 img {
    max-width: 100%;
}

.block3 {
    grid-column: 3 / 7;
    grid-row: 2 / 4;
}

.block4 {
    grid-column: 3 / 7;
    grid-row: 4 / 10;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 1fr);
    gap: 3px;
}

.block4_1 {
    grid-column: 1 / 3;
    grid-row: 1 / 5;
}

/* .block4_1 img {
    max-width: 100%;
    max-height: 100%;
} */

.block4_2 {
    grid-column: 3 / 11;
    grid-row: 1 / 3;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 5px;
}

.block4_2_text {
    width: 65%;
    font-size: 26px;
}

.block4_2_number {
    width: 35%;
    font-size: 70px;
}

.block4_3 {
    grid-column: 3 / 9;
    grid-row: 3 / 5;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 5px;
}

.block4_3_text {
    width: 65%;
    font-size: 19px;
}

.block4_3_number {
    width: 35%;
    font-size: 50px;
}

.block4_4 {
    grid-column: 9 / 11;
    grid-row: 3 / 7;
    display: flex;
    justify-content: center;
    align-items: center;
}

.block4_4 img {
    max-width: 100%;
    max-height: 100%;
}

.block4_5 {
    grid-column: 1 / 9;
    grid-row: 5 / 6;
    display: flex;
    align-items: center;
    padding: 5px;
}

.block4_6 {
    grid-column: 1 / 9;
    grid-row: 6 / 7;
    display: flex;
    align-items: center;
    padding: 5px;
}

.block4_5_number, .block4_6_number {
    font-size: 28px;
    font-weight: 800;
    padding: 0 10px;
}

.block4_5_text, .block4_6_text {
    font-size: 22px;
}

.block4_7 {
    grid-column: 1 / 7;
    grid-row: 7 / 11;
    display: flex;
    align-items: center;
}

.block4_7 img {
    max-width: 100%;
    max-height: 100%;
}

.block4_7_image {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.block4_7_text {
    width: 70%;
}

.block4_7_firstrow {
    display: flex;
    align-items: center;
}

.block4_7_firstrow_text {
    font-size: 22px;
    color: #9d0a0e !important;
}

.block4_7_firstrow_numbers {
    font-size: 40px;
    padding: 0 10px;
}

.block4_7_firstrow_numbers div {
    color: #9d0a0e;
}

.block4_8 {
    grid-column: 7 / 11;
    grid-row: 7 / 11;
    display: flex;
    align-items: center;
}

.block4_8 > div {
    width: 50%;
    max-height: 100%;
}

.block4_8 > div:first-child {
    display: flex;
    justify-content: center;
    height: 70%;
}

.block4_8 > div:last-child {
    font-size: 20px;
}

.block4_8 img {
    max-width: 100%;
    max-height: 100%;
}

.block5 {
    grid-column: 7 / 9;
    grid-row: 1 / 5;
    display: grid;
    grid-template-rows: repeat(7, 1fr);
    gap: 2px;
}

.block5_1 {
    grid-row: 1 / 3;
    display: flex;
    align-items: center;
}

.block5_1_1 {
    width: 50%;
    font-size: 40px;
    display: flex;
    justify-content: center;
}

.block5_1_2 {
    width: 50%;
    font-size: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.block5_1_2 div:first-child {
    font-size: 70px;
    font-weight: 700;
}

.block5_2 {
    grid-row: 3 / 5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.block5_2_1 {
    width: 39%;
    display: block;
}

.block5_2_3 {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.block5_2_2 {
    margin-left: 10px;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.block5_2_2 div:first-child {
}

.block5_2_2 div:last-child {
    font-size: 40px;
}

.block5_2 img {
    max-width: 100%;
    max-height: 100%;
}

.block5_3 {
    grid-row: 5 / 6;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.block5_3 div:first-child {
    font-size: 20px;
}

.block5_3 span {
    color: #9d0a0e;
}

.block5_4 {
    grid-row: 6 / 8;
    display: flex;
    align-items: center;
}

.block5_4_1 {
    width: 35%;
}

.block5_4_1 img {
    max-width: 100%;
    max-height: 100%;
}

.block5_4_2 {
    width: 65%;
}

.block5_4_2_item {
    display: flex;
    align-items: center;
}

.block5_4_2_item div:first-child {
    margin-left: 10px;
    font-size: 18px;
}

.block5_4_2_item div:last-child {
    padding-left: 10px;
    font-size: 50px;
}


.block6 {
    grid-column: 7 / 9;
    grid-row: 9 / 10;
    display: flex;
}

.block6_1 {
    width: 65%;
    /* text-align: right */
}

.block6_1_1 {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.block6_1_1 div:first-child {
    color: #9d0a0e;
    font-size: 90px;
    line-height: 0.8;
}

.block6_1_1 div:last-child {
    color: #9d0a0e;
    margin-left: 20px;
    font-size: 20px;
}

.block6_1_2 {
    margin-top: 8px;
    text-align: center;
}

.block6_1_2 span:last-child {
    font-weight: 800;
    font-size: 20px;
}

.block6_2 {
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.block6_2 img {
    max-width: 100%;
    max-height: 100%;
}

.block7 {
    grid-column: 7 / 9;
    grid-row: 5 / 9;
    font-size: 20px;
}

.block7 > div:last-child {
    margin-top: 10px;
}

.block7_detachments {
    padding-left: 30px;
    display: flex;
    width: 100%;
}

.block7_detachments span {
    color: #9d0a0e;
}

.block_Chief_title {
    font-size: 2rem;
    display: block;
}

.titleChief {
    font-weight: 600;
    margin-right: 1rem;
}

.block4_info {
    font-size: 1.3rem;
}

.avatar {
    width: 100px;
    margin: 1rem 0;
}

.inputBlock {
    margin-top: 27px;
}

.avatar img {
    object-fit: cover;
    width: 100px;
    border-radius: 100%;
    border: 2px solid brown;
}

.blockInfoChief {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: center;
}

@media (max-width: 1600px) {
    .content {
        grid-template-columns: repeat(2, 1fr);
        /* grid-template-rows: repeat(18, 50px); */
    }
    .block2 {
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        font-size: 36px;
    }
    
    .block3 {
        grid-column: 1 / 3;
        grid-row: 2 / 4;
    }
    
    .block4 {
        grid-column: 1 / 3;
        grid-row: 4 / 10;
    }
    
    .block1 {
        grid-column: 1 / 2;
        grid-row: 10 / 19;
    }

    .block5 {
        grid-column: 2 / 3;
        grid-row: 10 / 15;
    }
    
    .block6 {
        grid-column: 2 / 3;
        grid-row: 18 / 19;
    }
    
    .block7 {
        grid-column: 2 / 3;
        grid-row: 15 / 18;
    }
}

@media (max-width: 850px) {
    .content {
        
        grid-template-columns: 100%;
        /* grid-template-rows: repeat(53, 50px); */
    }
    .block2 {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        font-size: 36px;
    }
    
    .block3 {
        grid-column: 1 / 2;
        grid-row: 2 / 6;
    }
    
    .block4 {
        grid-column: 1 / 2;
        grid-row: 6 / 18;
    }
    
    .block1 {
        grid-column: 1 / 2;
        grid-row: 18 / 36;
    }

    .block5 {
        grid-column: 1 / 2;
        grid-row: 36 / 50;
    }
    
    .block6 {
        grid-column: 1 / 2;
        grid-row: 50 / 54;
    }
    
    .block7 {
        grid-column: 1 / 2;
        grid-row: 54 / 56;
    }
}

.regionBlock {
    display: block;
}

.regionList {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 64px;
    right: 0;
    width: 250px;
    list-style: none;
    height: 500px;
    overflow-y: auto;
    border: 1px solid rgb(233, 221, 221);
    box-shadow: 3px 3px 5px rgb(185 181 181);
    z-index: 1;
}

.regionItem {
    background-color: rgb(248, 239, 228);
    border: 1px solid rgb(233, 221, 221);
    box-shadow: 3px 3px 5px rgb(185 181 181);
    font-size: 14px;
    padding: .5rem 1rem;
}

.regionItem_active {
    background-color: rgb(233, 197, 152);
    border: 1px solid rgb(233, 221, 221);
    box-shadow: 3px 3px 5px rgb(185 181 181);
    color: #9d0a0e;
    font-size: 14px;
    padding: .5rem 1rem;
}

.regionItem:hover {
    background-color: rgb(233, 197, 152);
    color: #9d0a0e;
    cursor: pointer;
    transition: .4s;
}

.regionList::-webkit-scrollbar {
    width: .5rem;
  }
  
  .regionList::-webkit-scrollbar-track {
    background-color: #eee;
    border-radius: 1rem;
  }
  
  .regionList::-webkit-scrollbar-thumb {
    background-color: #9d0a0e;
    border-radius: 1rem;
  }

.titleBLock {
    display: flex;
    align-items: center;
    position: relative;
}

.updatedBlock {
    text-decoration: none;
    font-size: 1.5rem;
}

.icon {
    width: 35px;
    height: 35px;
    font-size: 35px;
    display: block;
    margin-left: 1rem;
    cursor: pointer;
    transition: .5s;
    transform: rotate(-180deg);
}

.icon_active {
    width: 35px;
    height: 35px;
    font-size: 35px;
    display: block;
    margin-left: 1rem;
    cursor: pointer;
    transition: .5s;
}

.block3_text {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.block3_texts {
    /* margin-right: 1.5rem; */
    font-size: 1.2rem;
}

.block7_1 {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.block7_images {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 1.2rem;
    width: 60%;
}

.researcher {
    margin-right: -60px;
    width: 55px;
    z-index: 1;
    margin-bottom: -30px;
}

@media (max-width: 768px) {
    .block4_2_text {
        width: 100%;
    }
    .block4_2_number {
        width: 100%;
    }
    .regionList {
        top: 80px;
    }
    .block2 {
        font-size: 2rem;
        padding: 0;
    }
    .block1_1_number {
        font-size: 5rem;
    }
    .block4 {
        grid-template-columns: repeat(10, 1fr);
        grid-template-rows: repeat(18, auto);
    }
    .block5 {
        grid-template-rows: repeat(7, auto);
    }
    .block1, .block3, .block4, .block5, .block6 {
        padding: 1rem;
    }
    .block4_1 {
        grid-column: 1 / 11;
        grid-row: 1 / 1;
        height: 200px;
        width: 200px;
        margin: 0 auto;
    }
    .block4_2 {
        grid-column: 1 / 11;
        grid-row: 2 / 2;
        height: 200px;
        margin-bottom: 2rem;
        flex-direction: column;
    }
    .block4_3 {
        grid-column: 1 / 11;
        grid-row: 3 / 3;
        height: 80px;
    }
    .block4_4 {
        grid-column: 1 / 11;
        grid-row: 4 / 4;
    }
    .block4_5 {
        grid-column: 1 / 11;
        grid-row: 5 / 5;
    }
    .block4_6 {
        grid-column: 1 / 11;
        grid-row: 6 / 6;
    }
    .block4_7 {
        grid-column: 1 / 11;
        grid-row: 7 / 7;
        height: 300px;
        margin: 2rem 0;
        flex-direction: column;
    }
    .block4_7 img {
        margin: 1rem 0;
    }
    .block4_8 {
        grid-column: 1 / 11;
        grid-row: 8 / 8;
        height: 200px;
    }
    .block4_7_text {
        width: 90%;
    }
    .block5_2 {
        margin: 1rem 0;
    }
    .block5_4 {
        margin: 1rem 0 0 0;
    }
    .block5_1 {
        flex-direction: column;
    }
    .block3 {
        flex-direction: column;
    }
    .block3_bar {
        min-width: 100%;
        margin: 0 auto;
    }
    .block3_bar > * {
        width: 100%;
    }
    .block3 > div:last-child {
        padding: 5px;
        width: auto;
        margin: 0 0 0 auto;
    }
    .block3_text span:first-child {
        display: block;
        min-width: auto;
        text-align: none;
    }
    
    .block3_text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .block3_text > div {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .block3_text span {
        font-size: 1rem;
        display: block;
        width: fit-content;
    }
    .inputBlock {
        margin-top: 0;
    }
    .block4_info {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 768px) {
    .mobile {
        display: flex;
        width: 100%;
    }

    .desktop {
        display: none;
    }
}

@media screen and (min-width: 769px) {
    .mobile {
        display: none;
        width: 100%;
    }

    .desktop {
        display: block;
        width: 50%;
        margin: 0 0 0 auto;
    }
}