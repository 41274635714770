.button {
    border: none;
    outline: none;
    background-color: rgb(175, 18, 18);
    color: white;
    padding: .5rem 3rem;
    border-radius: 10px;
    transition: .2s ease-in;
    cursor: pointer;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    display: flex;
    align-items: center;
    height: fit-content;
    position: relative;
}

.white {
    color: rgb(175, 18, 18);
    background-color: white;
}

.bordered {
    border: 1px solid white;
}

.button:hover {
    opacity: .8;
}

.button:disabled:hover {
    cursor: no-drop;
    opacity: 1;
}

.loaderWrap {
    position: absolute;
    top: calc(50% - 10px);
    left: .5rem;
}