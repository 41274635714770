.container {
  max-width: 1100px;
  width: 100%;
}

.title {
  width: 100%;
  height: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(45deg, rgb(185 26 26) 0%, rgb(253 196 126) 100%);
  padding: 0 16px;
  color: #ffffff;
  font-size: 23px;
  font-weight: 400;

  svg {
    cursor: pointer;
  }
}

.newsList {
  width: 100%;
  min-height: 523px;
  height: calc(100% - 34px);
  padding: 0 14px 70px;
  background-color: #fff;
  position: relative;

  > table {
    width: 100%;
    border-collapse: collapse;

    tbody tr {

      border-top: 1px solid rgb(226, 225, 225);
      cursor: pointer;

      &:hover {
        background-color: #fcf6f6b4;

        & span {
          
          color: rgb(16, 86, 80);
          // font-weight: 500;

          &::after {
            opacity: 1;
            transform: translateY(5px);
          }
        }
      }


    }

    th {
      flex-basis: 25%;
      color: #4c4c4c;
      font-size: 15px;
      font-weight: 700;
      text-align: left;
      padding: 0 .2rem;
    }

    td {
      padding:.2rem;

      &:nth-child(1) {

        & span {
          position: relative;

          &::after {
            position: absolute;
            bottom: 5px;
            left: 0;
            content: "";
            height: 1px;
            width: 100%;
            background-color: rgb(75, 112, 109);
            opacity: 0;
            transition: all 0.2s ease-in;
          }
        }
      }

      &:nth-child(2) {
        width: 160px;
        text-align: center;
      }

      &:nth-child(3) {
        width: 140px;
      }

      &:nth-child(4) {
        width: 40px;
      }

      &:nth-child(5) {
        width: 40px;
      }
      
      & > svg {
        color: rgb(204, 20, 20);
        cursor: pointer;
        font-size: 24px;
  
        &:hover {
          color: #ff0000;
        }
      }
    }

  }

}

.newsListHeader {
  padding: 10px 15px 10px 4px;

  > * + * {
    margin-top: 1rem;
  }

  > div {
    display: flex;
    gap: .5rem;
  }

}

.addEditModal {
  > form {

    > h1 {
      color: rgb(90, 17, 17);
      text-align: center;
    }

    > * + * {
      margin-top: .5rem;
    }

    .files {

      > p {
        font-size: .9rem;
        font-weight: 500;
        color: rgb(83, 1, 1);
        margin-bottom: 1px;
      }

      > div {
        display: flex;
        flex-wrap: wrap;
        gap: .5rem;
        min-height: 99px;
      }
    }

    > div:last-child {
      display: flex;
      justify-content: center;
      gap: 1rem;
      margin-top: 1rem;
    }
  }
}

.profileModal {
  max-width: 480px;
  width: 100%;
  height: auto;
  padding: 0;
  background-color: #fff;
  
  
  .modalBody {
    width: 100%;
    padding: 15px 15px 30px;

    .files {

      > p {
        font-size: .9rem;
        font-weight: 500;
        color: rgb(83, 1, 1);
        margin-bottom: 1px;
      }

      > div {
        display: flex;
        flex-wrap: wrap;
        gap: .5rem;
        min-height: 99px;
      }
    }

    > div:last-child {
      display: flex;
      justify-content: center;
      gap: 1rem;
      margin-top: 1rem;
    }

  }
}

.yunarmiansModal {
  max-width: 480px;
  width: 100%;
  height: auto;
  background-color: #fff;
  
  ul {
    padding: .5rem 0;
    list-style: none;

    > li {
      padding: .5rem 1rem;
      cursor: pointer;
      font-size: 1.2rem;
      color: #790e0e;

      &:hover {
        background-color: rgb(228, 227, 227);
      }
    }
  }

  > ul {
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all 0.2s ease-in;

      svg:hover {
        transform: scale(1.1);
      }
    }
  }

  > div:nth-child(2) {

    > select {
      width: 100%;
      border: none;
      outline: none;
      font-size: 24px;
      padding: 12px 0;
      border-radius: 10px;
      padding-left: 50px;
      padding-right: 50px;
      box-shadow: 0 0 5px rgb(196, 195, 195);
    }
    
    > ul {
      max-height: 20rem;
      overflow: auto;
    }

    > * + * {
      margin-top: .2rem;
    }
  }

  > div:last-child {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
  }
}

.petitionWrapper {
  width: 100%;
  min-height: 523px;
  height: calc(100% - 34px);
  padding: 20px 14px 70px;
  background-color: #fff;
  position: relative;

  > * + * {
    margin-top: 1rem;
  }

  > div {
    > b {
      color: #7d1a1a;
    }
    > span {
       margin-left: .3rem;
    }

  }

  .files {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
  }

  .peoples {
    ul {
      list-style: none;
      width: fit-content;

      > li {
        font-size: 1.2rem;
        padding: .5rem;
        cursor: pointer;

        &:hover {
          background-color: rgb(231, 230, 230);
        }
      }
    }

    > span {
      svg {
        cursor: pointer;
      }
    }
  }

  .modalButtons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    position: absolute;
    bottom: 1rem;
  }

  .modalButtons button {
    width: 125px;
    height: 30px;
    margin: 0 20px;
    font-size: 16px;
    border-radius: 25px;
  }
}

.searchInput {
  position: relative;
  display: block;
  width: 100%;
  
  input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 24px;
    padding: 12px 0;
    border-radius: 10px;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: 0 0 5px rgb(196, 195, 195);
  
  }
  
  svg:first-child {
   position: absolute;
   left: 10px;
   top: 10px;
   font-size: 30px;
  }
  
  svg:last-child {
   position: absolute;
   right: 10px;
   top: 10px;
   font-size: 30px;
   cursor: pointer;
   display: none;
  }
  
  &:hover svg:last-child {
   display: block;
  }
}

.statusModal {
  padding: 0 !important;

  ul {
    list-style: none;

    > li {
      padding: .5rem 1rem;
      cursor: pointer;
      font-size: 1.2rem;
      color: #790e0e;

      &:nth-child(even) {
        background-color: rgb(216, 215, 215);
      }

      &:hover {
        background-color: rgb(228, 227, 227);
      }
    }
  }
}
