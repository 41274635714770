* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  /* border: 1px solid rgb(255, 0, 0); */
}

html {
  background-color: rgb(242, 238, 222);
}

body::-webkit-scrollbar, body div::-webkit-scrollbar, ul::-webkit-scrollbar {
  background-color: rgba(238, 238, 238, 0.562);
  width: .8rem;
  height: .8rem;
}

body::-webkit-scrollbar-thumb, body div::-webkit-scrollbar-thumb, ul::-webkit-scrollbar-thumb {
  background-color: rgb(95, 95, 95);
  border-radius: 1rem;
}

@font-face {
  font-family: 'MyFont';
  src: local('MyFont'), url('./fonts/ofont.ru_Bebas.ttf') format('truetype');
}

.swiper-thumbs img {
  cursor: pointer;
} 

.swiper-thumbs {
  margin-top: 1rem;
}

.swiper-slide img {
  display: block;
  width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.swiper-slide :hover{
  cursor: pointer;
}

.swiper-slide-thumb-active {
  border: 2px solid #007aff;
}


.equipment__big img {
  width: 100%;
  max-width: 300px;
  height: 300px;
  object-fit: contain;
  margin: 0 auto;
}

.hero__big img {
  width: 100%;
  max-width: 300px;
  height: 182px;
  object-fit: contain;
  margin: 0 auto;
}

.equipment__small img {
  height: 100px;
  object-fit: contain;
}

.hero__small {
  margin-top: .5rem;
}

.hero__small img {
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.wrapperSliderEquipmentSmall i {
  display: none;
}

.parm {
  opacity: 0;
}

.parmactive {
  opacity: 1;
}

.swiper-slide .swiper-slide-active {
    zoom: 0.8;
}

.requestModal {
  max-width: none;
}

.react-fine-uploader-file-input {
  cursor: pointer !important;
} 

.react-fine-uploader-gallery-dropzone-content {
  font-weight: 400 !important;
  font-size: 20px !important;
}

.react-fine-uploader-gallery-dropzone-upload-icon {
  width: 24px !important; 
  height: 24px !important;
}

.react-fine-uploader-file-input-container {
  background-color: rgb(117, 194, 181) !important;
  border: 1px solid rgb(117, 194, 181) !important;
}

.react-fine-uploader-gallery-dropzone {
  border: 2px dashed rgb(117, 194, 181) !important;
}

.react-fine-uploader-gallery-file-input-container {
  display: block !important;
  width: 174px !important;
  margin: 0 auto !important;
  float: none !important;
  font-weight: 400 !important;
}

.react-fine-uploader-gallery-files {
  display: none !important; 
}

  .react-fine-uploader-total-progress-bar-container  {
    display: none !important;
    width: 0px !important;
    max-width: 0px !important;
    opacity: 0 !important;
    height: 0 !important;
    margin: 1rem auto !important;
    display: block !important;
    background-color: rgba(223, 223, 223, 0) !important;
    text-align: center !important;
  } 

.react-fine-uploader-gallery-nodrop-container, .react-fine-uploader-gallery-dropzone {
  padding: 15px !important;
  display: flex !important;
  align-items: center !important;
  flex-direction: column !important;
  max-height: 200px !important;
  min-height: 200px !important;
}

.progressbarWrapper {
  height: 30px;
  max-width: 500px;
  width: 80%;
  display: block;
  margin: auto;
  position: absolute;
  top: 80px;
  left: 10%;
  z-index: 0;
  background: #b1b1b1;
  padding: 3px;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
}

#greenBar {
  display: block;
  height: 100%;
  width: 0px;
  max-width: 500px;
  background-color: rgb(117, 194, 181);
  background-image: linear-gradient(
    center bottom,
    rgb(117, 194, 181) 37%,
    rgb(117, 194, 143) 69%
  );
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  font-size: 15px;
  text-align: center;
  color: white;
  transition: .5s;
}

.progressBytes {
  z-index: 3;
  text-align: center;
  position: relative;
  margin: 0 auto;
  display: block;
  width: 100%;
  color: #e9e9e9;
}

.react-fine-uploader-total-progress-bar-container .react-fine-uploader-gallery-total-progress-bar-container {
  display: none !important;
}

.rbc-month-row {
  overflow: initial !important;
}
