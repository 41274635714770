/* .container {
    width: 483px;
    height: 750px;
} */

.canvasBlock {
    width: fit-content;
    background-color: #fff;
    /* margin: 20px 0 0 20px; */
    border-radius: 5px;
    border: 2px dashed rgb(165, 165, 165);
    overflow: hidden;
}

