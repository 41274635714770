.wrapper {
    padding: 0 5rem;
    width: 100%;
}

.wrapper > h1 {
    text-align: center;
    margin: 2rem 0;
    color: rgb(175, 18, 18);
}

.tabsBlock {
    display: flex;
    list-style: none;
}

.tab {
    color: rgb(175, 18, 18);
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    opacity: 0.5;
    outline: none;
}

.activeTab, .tab:hover {
    opacity: 1;
}

.tab:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    height: 2px;
    width: 1rem;
    background-color: rgb(175, 18, 18);
    transition: .2s ease-in;
}

.activeTab:after, .tab:hover:after {
    width: 100%;
}

.tab + .tab {
    margin-left: 2rem;
}

.peoplesList {
    margin: 2rem 0;
}

.peoplesList > li > div, .itemLabel {
    font-weight: 600;
    font-size: 17px;
    color: #274684;
}

.accessWrapper {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}

.accessWrapper > ul {
    list-style: none;
    width: 80%;
}

.itemSelect {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 17px;
    font-weight: 600;
    color: green;
}

.itemSelect option {
    color: black;
}

.selectNotValue {
    color: red;
}

.selectReadValue {
    color: #ffbc00;
}

.modal {
    max-width: 500px;
    width: 100%;
    height: auto;
    padding: 0;
    background-color: #fff;
    overflow: initial;
}

.title {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  background: linear-gradient(45deg, rgb(185 26 26) 0%, rgb(253 196 126) 100%);
  padding: 0 16px;
  color: #ffffff;
  font-size: 23px;
  font-weight: 400;
}

.modalBody {
    width: 100%;
    padding: 15px 15px 30px;
}

.modalBody > div + div {
    margin-top: .5rem !important;
}

.modalButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.modalButtons button {
  width: 125px;
  height: 30px;
  margin: 0 20px;
  font-size: 16px;
  border-radius: 25px;
}