.wrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
}

.currentProfilesBlock {
    position: fixed;
    top: 50px;
    right: -350px;
    background-color: aliceblue;
    transition: all 0.2s ease-in;
    z-index: 1;
    padding: 1rem 0 1rem 1rem;
    border-radius: 5px;
    border: 1px solid rgb(211, 211, 243);
}

.currentProfilesBlock.active {
    top: 100px;
    right: 10px;
}

.currentProfilesBlock table {
    display: none;
}

.closeButton {
    position: absolute;
    top: 2px;
    right: 2px;
    font-size: 24px;
    cursor: pointer;
    color: rgb(163, 6, 6);
    transition: all 0.2s ease-in;
}

.closeButton:hover {
    transform: rotate(5deg);
}

.currentProfilesList {
    margin-top: 20px;
    min-height: 200px;
    max-height: 300px;
    overflow: auto;
    margin-right: .5rem;
}

.currentProfilesList div {
    margin-right: .5rem;
    border: 1px solid rgb(202, 198, 198);
    padding: 3px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.currentProfilesList span:nth-child(1) {
    display: inline-block;
    width: 20px;
}

.currentProfilesList span:nth-child(2) {
    display: inline-block;
    width: 250px;
    overflow: hidden;
}

.currentProfilesList svg {
    font-size: 22px;
    cursor: pointer;
}

.currentProfilesList div + div {
    margin-top: 10px;
}
    

.currentButtonsBlock {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
    width: 300px;
}

.currentButtonsBlock button:nth-child(1) {
    width: 38%;
}

.currentButtonsBlock button:nth-child(2) {
    width: 58%;
}

.currentButtonsBlock button:nth-child(3) {
    width: 100%;
}


.currentButtonsBlock button {
    background-color: rgb(117, 194, 181);
    color: rgba(252, 248, 230, 0.87);
    padding: .5rem 1rem;
    border-radius: 3px;
    transition: opacity 0.2s ease-in;
    margin: 3px;
}

.currentButtonsBlock button:hover {
    opacity: 0.8;
}

.filters {
    background-color: rgba(255, 255, 255, 0.377);
    box-shadow: 0px 0px 11px #c3c3c3;
    max-width: 300px;
    width: 100%;
    padding: 10px;
}

.filterTitle {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: grey;
    padding-bottom: 10px;
    border-bottom: 1px solid grey;
}

.filterName {
    font-size: 16px;
}

.checkboxFirst {
    border-top: 1px solid rgba(128, 128, 128, .5) ;
}

.checkbox {
    padding: 5px;
    font-size: 15px;
    color: brown;
    width: 100%;
}

.checkbox input {
    margin-right: 1rem;
}

.checkbox label {
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;
}

.filterFooter {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-top: 1px solid grey;
}

.filterFooter div{
    color: rgb(87, 87, 87);
    font-weight: 500;
    padding: .8rem 0;
}

.searchButtons {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.searchButtons svg {
    margin-right: .2rem;
}

.filterFooter button {
    color: rgb(192, 22, 22);
    font-weight: 500;
    font-size: 15px;
    padding: .3rem .4rem;
    display: flex;
    align-items: center;
    width: fit-content;
    border-radius: 5px;
    transition: all 0.2s ease-in;
    border: 1px solid rgb(192, 22, 22);
}

.filterFooter button:first-child {
    border: 1px solid rgb(192, 22, 22);
    color: rgb(192, 22, 22);
}

.filterFooter button:last-child {
    background-color: rgb(192, 22, 22);
    color: #fff;
}

.filterFooter button:hover{
    /* color: rgb(163, 6, 6); */
}

.filterFooter button:active{
    border: 1px solid rgb(170, 168, 168);
}

.importBtn {
    font-size: 14px;
    background-color: rgb(117, 194, 181);
    color: rgba(252, 248, 230, 0.87);
    border: none;
    padding: .5rem 1rem;
    border-radius: 3px;
    transition: opacity 0.2s ease-in;
    margin: 0 0 0 .5rem;
    cursor: pointer;
}

.importBtn:hover {
    opacity: 0.8;
}

.profilesBlock {
    width: 100%;
    padding: 0 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: calc(100% - 300px);
}

.viewBlock {
    display: flex;
    justify-content: space-between;
    width: 92%;
}

.viewModeButton {
    margin: 2px;
    border: 3px solid gray;
    color: gray;
    background-color: rgba(255, 255, 255, 0.342);
    border-radius: 3px;
    font-size: 1.8rem;
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease-in;
}

.viewBlock > div {
    display: flex;
}

.viewModeButton:hover {
    opacity: 0.8;
}

/* .viewBlock button {
    border: 3px solid gray;
    background-color: transparent;
    color: gray;
    transition: all 0.2s ease-in;
} */

.importBtnUn {
    border: 3px solid gray;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.342);
    color: gray;
    transition: all 0.2s ease-in;
    padding: .6rem;
    margin: 0 .3rem .3rem;
}

.importBtnUn:hover {
    border: 3px solid rgb(92, 16, 16);
    color: rgb(92, 16, 16);
}

.viewBlock button:hover {
    border: 3px solid rgb(92, 16, 16);
    color: rgb(92, 16, 16);
}

.pagination {
    max-width: 650px;
    width: 100%;
    padding: 10px 0;
}

.profilesListList {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.profilesListList a {
    text-decoration: none;
}

.profilesListListItem {
    cursor: pointer;
    display: flex;
    border: 1px solid black;
    border-radius: 3px;
    margin: 0 .5rem;
    width: 700px;
    height: 30px;
    background-color: aliceblue;
    color: rgb(82, 80, 80);
}

.profilesListListItem div:first-child{
    min-width: 35px;
    text-align: center;
    border-right: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profilesListListItem div:nth-child(2) {
    padding-left: 5px;
    display: flex;
    align-items: center;
    max-width: 450px;
    width: 100%;
    border-right: 1px solid black;
    overflow: hidden;
    white-space: nowrap;
}

.profilesListListItem div:last-child{
    padding-left: 5px;
    display: flex;
    align-items: center;
    width: 195px;
    overflow: hidden;
    white-space: nowrap;
}

.profilesList {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}


.selectItem {
    color: rgb(124, 3, 3);
    font-size: 19px;
    padding: 3px 0 3px 10px;
}

.selectItem input {
    cursor: pointer;
    margin-right: 10px;
}

.selectItem label {
    cursor: pointer;
}

.filterTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 500;
    color: grey;
    padding: 10px 0;
    cursor: pointer;
    /* border-top: 1px solid hsla(0,0%,50.2%,.377); */
}

.filterTitle div {
    display: flex;
    align-items: center;
}

.filterTitle svg {
    margin: 0 20px 0 5px;
}

.selectBlock {
    overflow: auto;
}

.activeGroup {
    color: #669999;
}


.currentProfilesList::-webkit-scrollbar, .regionList::-webkit-scrollbar {
    width: .5rem;
  }
  
.currentProfilesList::-webkit-scrollbar-track, .regionList::-webkit-scrollbar-track {
    background-color: #eee;
    border-radius: 1rem;
}

.currentProfilesList::-webkit-scrollbar-thumb, .regionList::-webkit-scrollbar-thumb {
    background-color: #9d0a0e;
    border-radius: 1rem;
}

.wapperPopup {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, .2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.popup {
    background-color: rgba(252, 248, 230, 1);
    width: 100%;
    max-width: 1270px;
    padding: 2rem 1.5rem 2rem;
    border-radius: 3px;
    position: relative;
    display: block;
}

.popupTitle {
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: #9d0a0e;
    text-align: center;
}

.popupMessage {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 2rem;
    color: #9d0a0e;
    text-align: center;
}

.closePopupButton {
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.5rem;
    cursor: pointer;
}

.closePopupButton:hover {
    color: #9d0a0e;
    transition: .5s;
}

.inputBlock {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.regionBlock {
    display: block;
    position: relative;
}

.regionBlock_First {
    display: block;
    position: relative;
    width: fit-content;
    /* max-width: 100%; */
    width: 100%;
}

.regionList_first {
    position: absolute;
    right: 0;
    top: 35px !important;
    z-index: 5;
}

.regionList {
    display: flex;
    flex-direction: column;
    top: 64px;
    right: 0;
    width: 200px;
    list-style: none;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid rgb(233, 221, 221);
    box-shadow: 3px 3px 5px rgb(185 181 181);
}

.regionItem {
    background-color: rgb(248, 239, 228);
    border: 1px solid rgb(233, 221, 221);
    box-shadow: 3px 3px 5px rgb(185 181 181);
    font-size: 13px;
    padding: .5rem 1rem;
}

.regionItem_active {
    background-color: rgb(233, 197, 152);
    border: 1px solid rgb(233, 221, 221);
    box-shadow: 3px 3px 5px rgb(185 181 181);
    color: #9d0a0e;
    font-size: 14px;
    padding: .5rem 1rem;
}

.regionItem:hover {
    background-color: rgb(233, 197, 152);
    color: #9d0a0e;
    cursor: pointer;
    transition: .4s;
}

.inputName {
    font-weight: 500;
    font-size: 1rem;
    display: block;
    padding: 0 0 .5rem;
}

.activeDep {
    font-weight: 500;
    font-size: 1rem;
    display: block;
    padding: 0 0 .5rem .5rem;
    color: brown;
}

.buttonsPopup {
    display: flex;
}

.sendButton {
    background-color: rgb(117, 194, 181);
    color: rgba(252, 248, 230, 0.87);
    padding: .5rem 1rem;
    border-radius: 3px;
    transition: opacity 0.2s ease-in;
    margin: 1rem 0 0;
}

.sendButton:disabled, .resetButton:disabled {
    cursor: not-allowed;
}

.sendButton:hover {
    opacity: 0.8;
}

.resetButton {
    background-color: transparent;
    color: #9d0a0e;
    padding: .2rem 1rem;
    border-radius: 3px;
    transition: opacity 0.2s ease-in;
    margin: 1rem 0 0 1rem;
    border: 1px solid #9d0a0e;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.resetButton:hover {
    background-color: #9d0a0e;
    color: #fff;
    text-replace: .4s;
}

.buttonText {
    display: block;
}

.resetButtonIcon {
    font-size: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
}

.inputSearch {
    width: 100%;
    font-weight: 500;
    margin: 0 0 .5rem 0;
    padding: .2rem;
    outline: none;
    border-radius: .2rem;
    border: 3px solid #9d0a0e;
}

.nameAndArrow {
    display: flex;
    align-items: center;
}

.icon {
    width: 30px;
    height: 30px;
    font-size: 30px;
    display: block;
    margin-left: .5rem;
    cursor: pointer;
    transition: .5s;
    transform: rotate(-180deg);
    color: brown;
    margin-bottom: .5rem;
}

.icon_active {
    color: brown;
    width: 30px;
    height: 30px;
    font-size: 30px;
    display: block;
    margin-left: .5rem;
    margin-bottom: .5rem;
    cursor: pointer;
    transition: .5s;
}

.closeBtn {
    display: none;
}

.modal {
    max-width: 60vw;
    border-radius: 15px;
}

.printBadgesWrapper h3 {
    text-align: center;
    margin: .5rem 0 1.5rem;
    color: #cc0606;
    font-size: 1.5rem;
}

.printBadgesWrapper > button {
    margin-top: 15px;
}

.swiper-button-prev,  .swiper-button-next {
    background-color: #000 !important;
    color: #000 !important;
}

.swiper-slide-thumb-active {
    color: red !important;
}

.printButtonsBlock {
    display: flex;  
}

.printPreviewWrap {
    max-height: 60vh;
    overflow: auto;
}

.printPreviewBlock {
    display: flex;
    flex-wrap: wrap;
}

.printPreviewBlock > div {
    pointer-events: none;
    zoom: 0.7;
    margin: 10px;
}

.filtersHeaderSearch {
    color: white;
    background-color: rgb(192, 22, 22);
    font-weight: 500;
    font-size: 15px;
    padding: .3rem .4rem;
    display: flex;
    align-items: center;
    width: fit-content;
    border-radius: 5px;
    transition: all 0.2s ease-in;
    border: 1px solid rgb(192, 22, 22);
}

.filtersHeaderSearch svg {
    margin: 0;
    margin-right: 0.2rem;
}

@media screen and (max-width: 678px) {
    .popup {
        height: 100vh;
        overflow-y: auto;
        margin: .5rem;
    }
    
    .inputBlock {
        flex-direction: column;
    }

    .regionBlock {
        margin: 1rem auto;
        width: 100%;
    }
    
    .buttonsPopup {
        justify-content: center;
    }

    .regionList {
        width: auto;
    }
}

@media (max-width: 1024px) {

    .wrapper {
        flex-direction: column;
    }

    .viewBlock {
        margin-top: 2rem;
        width: 100%;
    }

    .filters {
        max-width: 100%;
    }

    .profilesBlock {
        max-width: 100%;
        padding: 0;
    }

    .profilesList {
        justify-content: space-between;
    }

    .filterFooter {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .filtersList {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .pagination {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .button__reset {
        margin: 0 0 0 auto;
    }

    .button__search {
        margin-left: 1rem;
    }

}

@media (max-width: 976px) {
    .profilesList {
        justify-content: center;
    }
}

@media (max-width: 444px) {
    .viewBlock {
        margin: 2rem 0 0 0;
    }

    .viewBlock + div {
        flex-direction: column;
        margin: 0 0 .5rem 0;
    }
    .viewBlock > div > div {
        display: flex;
        flex-direction: column;
    } 

    .importBtn {
        margin: 0 .5rem .5rem 0;
    }
}

@media (max-width: 682px) {
    
    .profilesList {
        justify-content: center;
        width: 100%;
    }

    .button__reset {
        margin: 0 .5rem 0 0;
    }

    .button__search {
        margin-left: 0;
    }
    
    .profilesListListItem {
        margin-top: 1rem;
        width: 100%;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: .3rem;
    }

    .profilesListListItem div:first-child{
        border-right: none;
    }

    .profilesListListItem div:nth-child(2) {
        border-right: none;
        margin: .3rem;
        width: auto;
        white-space: break-spaces;
    }

    .profilesListListItem div:last-child {
        width: auto;
    }

    .profilesListList a {
        width: 100%;
    }
}

.localSelectWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}

.localSelect select {
    border-radius: 0;
    border: 3px solid brown;
    outline: none;
}

.titleDate {
    font-size: .9rem;
    font-weight: 500;
    color: rgb(83, 1, 1);
    margin-bottom: 1px;
}

.pickerProfile {
    margin: 0 0 0 0;
    padding: 1px .5px;
    width: 100%;
    background-color: #fff;
    outline: none;
    border: 3px solid brown;
    font-size: 18px;
    font-weight: 500;
    color: #000;
    user-select: none;
    cursor: pointer;
}