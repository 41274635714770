.yearlyCalendar {
  text-align: center;
}

.yearlyMonthWrapper {
}

.yearlyMonth {
  display: inline-block;
  float: left;
  height: 300px;
  padding: 20px;
  box-sizing: border-box;
  width: calc(100% / 3);
  .yearlyMonthName {
    margin: 20px 0;
    text-align: left;
  }
  .weekRow {
    height: 30px;
  }
  .calendarDayOfWeek {
    div {
      text-align: center;
    }
  }
  .inactiveDay {
    .currentDay {
      color: #b5b5b5;
      display: unset;
      border-radius: unset;
      background-color: transparent;
    }
  }
  .dayCell {
    height: 30px;
    text-align: center;
    .dayDate {
      font-size: 0.8em;
      display: inline-block;
      width: 100%;
      height: 100%;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .dayText {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
    .dayDate.currentDay {
      width: 50%;
      height: 50%;
    }
  }
}

.calendarWrapper {
  width: 100%;
  color: #202029;
}

@media only screen and (max-width: 426px) {
    .yearlyMonth {
        width: calc(100% / 2);
    }
}
