.absolute {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
    background-color: rgba(0, 0, 0, 0.178);
}

.confirmation {
    width: 400px;
    background-color: #ffffff;
    border: 1px solid #a09f9f;
    border-radius: 4px;
    padding: 20px;
}

.textBlock {
    width: 100%;
    min-height: 100px;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
}

.textBlock span {
    font-style: italic;
    color: #a09f9f;
    display: block;
    overflow-wrap: anywhere;
}

.buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid rgb(199, 197, 197);
}