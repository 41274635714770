
.sizeSelectBlock {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sizeSelectBlock div {
    font-size: 3rem;
    font-style: italic;
    color: grey;
    cursor: pointer;
    transition: all 0.2s ease-in;
}

.sizeSelectBlock div:hover {
    color: #289288;
    /* text-decoration: underline; */
}

.slideWrap {
    max-width: 357.1px;
    height: 500px;
    position: relative;
    width: fit-content;
    margin: 0 auto;
    background-color: rgb(216, 216, 216);
    box-shadow: 0 0 22px #919191;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slidefromUnarmians {
    height: 350px;
    max-width: 250px;
}

.slideWrap img {
    max-width: 100%;
    max-height: 100%;
}

.slideWrapNew{
    display: flex;
    justify-content: center;
    align-content: center;
    background-color: rgb(216, 216, 216);
}

.slideWrap .createButton {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #000;
    background-color: #fff;
    border-radius: .5rem;
    padding: .3rem;
    width: 2rem;
    height: 2rem;
    display: none;
    transition: all 0.2s ease-in;
}

.slideWrap:hover .createButton {
    display: block;
}

.slideWrap .createButton:hover {
    transform: scale(1.1);
}

.modal {
    max-width: none;
}

.closeButton {
    display: none;
}

.container {
    display: flex;
}

.buttonsBlock {
    display: flex;
    justify-content: space-evenly;
    margin-top: 15px;
}

.settings {
    width: 400px;
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid rgb(180, 179, 179);
}

.settingsGroup {
    border-radius: 5px;
    background-color: rgb(253, 248, 242);
}

.settingsGroup + .settingsGroup {
    margin-top: 1px;
}

.blockLabel {
    background-color: rgb(36, 145, 136);
    padding: 5px;
    border-radius: 5px;
    color: rgb(245, 249, 252);
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.blockLabel .createButton {
    transition: all 0.2s ease-in ;
}

.groupWrap {
    height: 727px;
    overflow: auto;
    position: relative;
    padding-right: .3rem;
}

.imagesBlock {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-height: 620px;
    overflow: auto;
}

.imagesBlock div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin: 10px;
    cursor: pointer;
    position: relative;
}

.imagesBlock svg {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 24px;
    color: red;
    transition: 0.2s;
    display: none;
}

.imagesBlock svg:hover {
    transform: scale(1.3);
}

.imagesBlock div:hover svg {
    display: block;
}

.imagesBlock img {
    max-width: 100%;
    max-height: 100%;
}

.addedImages {
    display: flex;
    overflow-x: auto;
    width: 100%;
    background: #fdf8f2;
    border-top: 2px solid #289288;
    padding: 0 1rem;
}

.imagesButtonsBlock {
    display: flex;
    justify-content: center;
}

.imagesButtonsBlock > button {
    margin: 1rem auto;
    display: block;
    width: fit-content;
    background-color: rgba(83, 85, 85, 0.082);
    padding: .5rem 1rem;
    font-size: .9rem;
    border: 1px solid rgb(177, 171, 171);
    border-radius: 5px;
    color: rgb(119, 119, 119);
    cursor: pointer;
    font-weight: 500;
    display: block;
    width: fit-content;
    margin: 1rem auto;
}

.imagesButtonsBlock > button:hover {
    background-color: rgba(83, 85, 85, 0.26);
    color: rgb(255, 255, 255);
    transition: all 0.2s ease-in;
    border-color: rgb(204, 206, 206);
}

.textPlugsBlock {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}

.textPlugsBlock > div {
    margin: 2px;
    background-color: rgb(143, 30, 30);
    color: white;
    border-radius: 5px;
    padding: 3px 7px;
    cursor: pointer;
    opacity: 0.7;
}

.textItem {
    margin-top: 10px;
    border: 2px solid brown;
    border-radius: 5px;
    padding: 5px;
    background-color: aliceblue;
    position: relative;
}

.textItem > div {
    word-break: break-all;
    width: 100%;
}

.textItem > svg {
    color: red;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 30px;
    padding: 0;
    cursor: pointer;
    display: none;
}

/* .textItem:hover svg {
    display: block;
} */

.textInput {
    width: 100%;
    display: block;
    line-height: 20px;
    font-weight: 500;
    margin: 0 0 .5rem 0;
    padding: .2rem;
}

.fontSize {
    width: 35px;
    line-height: 20px;
    font-weight: 500;
    padding: .2rem;
}

.fontSizeWrapper {
    margin: 0 .5rem 0 0;
}

.colorWrapper {
    margin: 1rem 0;
}

.wrapperEdit {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.edit {
    cursor: pointer;
    width: 1rem;
    height: 1rem;
}

.transformButton {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-content: center;
    border: 2px solid transparent;
    font-size: 28px;
    border-radius: 5px;
    cursor: pointer;
    transition: border .3s ease-in-out;
    margin: 0 0 0 .8rem;
}

.transformButton:hover {
    border: 2px solid crimson;
}

.controls_line {
    display: flex;
    justify-content: flex-start;
    align-content: center;
}

.newBadge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 350px;
    max-width: 100%;
    max-height: 100%;
    background-color: rgb(219, 219, 219);
}

.newBadge i svg{
    color: #fff;
    width: 100px;
    height: 100px;
}   

.addImageBlock {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #fdf8f2;
}