.wrapper {
    display: flex;
    justify-content: center;
}

.answersL div {
    color: #9d0a0e;
    margin: 2vw;
    font-size: 3.5vw;
    background-color: #fff;
    padding: 2vw 5vw;
    border-radius: 100px;
    margin-bottom: 4vw;
}

.answersR div {
    color: #9d0a0e;
    margin: 2vw;
    font-size: 3.5vw;
    background-color: #fff;
    padding: 2vw 5vw;
    border-radius: 100px;
    margin-bottom: 4vw;
}