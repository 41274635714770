.addTest {
    min-height: calc(100% - 56px);
}

.addTestContainer {
    height: auto;
    width: 1100px;
    background: #fff;
    margin-bottom: 100px;
}

.addTestTitle {
    width: 100%;
    height: 34px;
    display: flex;
    align-items: center;
    background: linear-gradient(45deg, rgb(185 26 26) 0%, rgb(253 196 126) 100%);
    padding: 0 16px;
    color: #ffffff;
    font-size: 23px;
    font-weight: 400;
}

.addTestBody {
    height: calc(100% - 34px);
    display: flex;
    justify-content: space-between;
    padding: 15px 15px 30px;
    min-height: 423px;
    background: white;
}

.addTestBodyLeft {
    width: 100%;
    flex-direction: column;
    margin-right: 15px;
}

.addTestBodyRight {
    width: 200px;
}

.addTestBodyLeft button {
    background-color: #a59e9e;
    padding: 5px;
    border-radius: 3px;
    color: white;
    font-weight: 400;
    transition: all 0.2s ease-in;
}

.addTestBodyLeft button:hover {
    background-color: rgb(28, 133, 124);
}

.addTestBodyLeft h4 {
    margin: 10px 0;
    color: rgb(105, 14, 14);
}

.label {
    color: #989898;
    font-size: 12px;
    margin: 5px 0;
}

.newsDatepicker input {
    width: 200px;
  height: 24px;
  border: 1px solid #cecece;
  background-color: #ffffff;
  padding: 0px 7px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #000000;
  font-family: 'Proxima Nova';
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
  background: url('../../../assets/images/news-datepicker-arrow.png') 97% center no-repeat;
  cursor: pointer;
}

.addQuestionBlock {
    position: relative;
    margin-top: 30px;
}

.selectQuestionTypeBlock {
    display: block;
}

.selectQuestionTypeBlock button {
    margin: 5px;
    
}

.questionBlockTitle {
    margin-bottom: 5px;
    color: rgb(28, 133, 124);
}

.optionQuestionBlock {
    border: 1px solid rgb(105, 11, 11);
    border-radius: 10px;
    padding: 5px;
    background-color: white;
}

.optionQuestionBlock > div {
    margin-bottom: 20px;
}

.imageBox {
    width: 120px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(187 184 184);
    border-radius: 10px;
    box-shadow: 0 0 10px rgb(187 184 184);
    position: relative;
    overflow: hidden;
    margin-bottom: .5rem;
}

.imageBox img {
    max-width: 100%;
    max-height: 100%;
}

.imageBox > svg {
  color: red;
  font-size: 26px;
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  transition: all 0.2s ease-in;
  animation: appearance 0.2s ease-in;
  cursor: pointer;
}

.imageBox > svg:first-child {
  font-size: 18px;
  top: 3px;
  right: 20px;
  color: #203372;
}

.imageBox > svg:hover {
  transform: scale(1.2);
}

.imageBox:hover svg {
    display: block;
}

.answersBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.pointsRow {
    width: 90%;
    display: flex;
    justify-content: flex-end;
    color: grey;
}

.optionQuestionBlock textarea{
    width: 80%;
}


.optionQuestionBlock textarea {
    border-radius: 5px;
}

.editAnswerItem {
    display: flex;
    align-items: center;
}

.editAnswerItem > input {
    width: 10%;
    border: 1px solid grey;
    border-radius: 3px;
    margin-left: 5px;
    height: 25px;
}

.editAnswer {
    position: relative;
    display: flex;
    align-items: center;
    width: 90%;
    height: 25px;
    border: 1px solid grey;
    border-radius: 3px;
    margin: 5px 0;
    /* max-width: 250px; */
    width: 80%;
}

.categoryBlock {
    margin-bottom: .2rem;
    font-size: .9rem;
    font-style: italic;
}

.categoryBlock select {
    border: none;
    outline: none;
    font-style: italic;
}

.categoryBlock select:disabled {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

.selectedBlock {
    width: 30px;
    height: 100%;
    border-right: 1px solid grey;
    border-left: 1px solid grey;
}

.inputFirstChild {
    width: 20px;
    border: none;
    outline: none;
    padding-left: 2px;
}

.inputLastChild {
    width: 100%;
    border: none;
    outline: none;
}

.deleteItem {
    cursor: pointer;
    color: rgba(255, 0, 0, 0.616);
    position: absolute;
    right: 1px;
    top: 1.5px;
}

.answersBlock button {
    width: 100%;
    max-width: 250px;
    margin: 5px 0;
}

.closeButton {
    position: absolute;
    top: 7px;
    right: 7px;
    color: rgba(255, 0, 0, 0.616) !important;
    cursor: pointer;
}

.filesList div {
    display: flex;
    align-items: center;
    color: rgb(110, 109, 109);
}

.filesList span:last-child {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.moreWordsBlock > textarea {
    width: 50%;
    height: 70px;
}