.container {
  max-width: 1100px;
  width: 100%;
}

.title {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  background: linear-gradient(45deg, rgb(185 26 26) 0%, rgb(253 196 126) 100%);
  padding: 0 16px;
  color: #ffffff;
  font-size: 23px;
  font-weight: 400;
}

.newsList {
  width: 100%;
  min-height: 523px;
  height: calc(100% - 34px);
  padding: 0px 14px 70px;
  background-color: #fff;
  position: relative;
}

.newsListHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px 10px 4px;
}

.tableHeader {
  display: flex;
  align-items: center;
  min-height: 36px;
  border-bottom: 1px solid #c4c4c4;
}



.tableHeader div:first-child {
  color: #4c4c4c;
  font-size: 15px;
  font-weight: 700;
}

.tableRow {
  padding: 3px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d8d5d5;
}

.tableRow:hover {
  background-color: #fcf6f6b4;
}

.displayname {
  color: #5b5c61;
  cursor: pointer;
  flex-basis: calc(100% - 24px);
}

.displayname span {
  position: relative;
  margin-left: 5px;
}

.displayname span::after {
  position: absolute;
  bottom: 5px;
  left: 0;
  content: "";
  height: 1px;
  width: 100%;
  background-color: rgb(75, 112, 109);
  opacity: 0;
  transition: all 0.2s ease-in;
}

.displayname:hover span {
  color: rgb(16, 86, 80);
  font-weight: 500;
}

.displayname:hover span::after {
  opacity: 1;
  transform: translateY(5px);
}

.actions svg {
  color: rgb(204, 20, 20);
  cursor: pointer;
  font-size: 24px;
}

.actions svg:hover {
  color: #ff0000;
}

.deleteIcon {
  width: 20px;
  cursor: pointer;
}

.pagination {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
}

.controls {
  position: absolute;
  bottom: 25px;
  left: 25px;
  display: flex;
  align-items: center;
}

.sortBlock {
  border: 1px solid rgb(187, 184, 184);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.sortBlock:active {
  background-color: rgb(250, 226, 192);
}

.sortActive {
  background-color: rgb(208, 253, 238);
  border-color: rgb(148, 146, 146);
}

.sortBlock svg {
  font-size: 20px;
  color: rgb(187, 184, 184);
}

.sortActive svg {
  color: rgb(148, 146, 146);
}

.sortBlock:hover {
  transform: scale(1.05);
}

.itemsCount {
  margin-left: 10px;
  user-select: none;
}

.itemsCount select {
  color: grey;
  cursor: pointer;
  outline: none;
  border-radius: 3px;
}

.itemsCount span {
  margin-left: 5px;
  color: grey;
}


/*==============================MODAL===================================*/

.closeBtn {
  display: none;
}

.modal {
  max-width: 900px;
  width: 100%;
  height: auto;
  padding: 0;
  background-color: #fff;
  overflow: hidden;
}

.modalBody {
  width: 100%;
  padding: 15px 15px 30px;
}

.firstRow {
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 10px;
}

.firstRow>div {
  width: 50%;
}

.label {
  font-size: .9rem;
  color: rgb(88, 10, 10);
  font-weight: 500;
}

.regionDepartmentsList {
  max-height: 200px;
  margin-bottom: 30px;
  overflow: auto;
  display: flex;
}

.regionDepartmentsList div {
  width: 50%;
}


.otherDepartmentsList {
  margin-bottom: 30px;
  display: flex;
}

.otherDepartmentsList > div {
  width: 50%;
}

.checkBoxBlock {
  min-height: 100px;
  max-height: 200px;
  overflow: auto;  
}

.regionDepartmentsList::-webkit-scrollbar, .checkBoxBlock::-webkit-scrollbar {
  width: .5rem;
}

.regionDepartmentsList::-webkit-scrollbar-track, .checkBoxBlock::-webkit-scrollbar-track {
background-color: #eee;
border-radius: 1rem;
}

.regionDepartmentsList::-webkit-scrollbar-thumb, .checkBoxBlock::-webkit-scrollbar-thumb {
background-color: #9d0a0e;
border-radius: 1rem;
}

.input {
  width: 90%;
  padding: 1px 0;
  font-size: 18px;
  font-weight: 500;
}

.select {
  width: 90%;
  font-size: 18px;
  font-weight: 500;
}

.filesBlock {
  margin-top: 15px;
  width: 90%;
  word-break: break-all;
}

.fileItem {
  display: flex;
  padding-left: 10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px dashed rgb(119, 118, 118);
}

.fileItem:hover {
  border: 1px solid rgb(119, 118, 118);
}

.fileItem a {
  width: 90%;
}

.fileItem+.fileItem {
  margin-top: 5px;
}

.imgBlock {
  margin: 4px 10px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.imgBlock img {
  max-width: 100%;
  max-height: 100%;
}

.input {
  margin-top: 10px;
}

.tasksBlock {
  position: relative;
}

.tasksBlock h3 {
  color: rgb(134, 72, 72);
  margin: 10px 0 5px 0;
}

.addTask {
  position: absolute;
  top: -5px;
  right: 20px;
  font-size: 30px;
  color: rgb(160, 66, 66);
  transition: all 0.2s ease-in;
  cursor: pointer;
}

.addTask:hover {
  transform: scale(1.2);
}

.tasksBlock table {
  border-collapse: collapse;
}

.tasksBlock th {
  color: #3a3a3a;
}

.tasksBlock td {
  text-align: center;
}

.tasksBlock th, .tasksBlock td {
  border: 1px solid rgb(187, 181, 181);
}

.headRow th:first-child {
  width: 5%;
}

.headRow th:nth-child(2) {
  width: 85%;
}

/* .headRow th:nth-child(3) {
    width: 30%;
  } */

.headRow th:nth-child(3) {
  width: 5%;
}

.headRow th:nth-child(4) {
  width: 5%;
}

.tableItem td:nth-child(2) {
  text-align: left;
  padding-left: 20px;
  cursor: pointer;
  word-break: break-word;
}

/* .tableItem td:nth-child(3) {
    text-align: left;
    padding-left: 20px;
  } */

.tableItem td:nth-child(3) {
  font-size: 22px;
  font-weight: 500;
  color: #4c4c4c;
  text-align: center;
}

.tableItem td:last-child {
  border: none;
  background-color: white !important;
}

.tableItem img {
  max-width: 100%;
}

.tableItem svg {
  color: rgb(204, 20, 20);
  cursor: pointer;
  font-size: 24px;
}

.tableItem svg:hover {
  color: #ff0000;
}

.tableItem:hover {
  background-color: #faf8f8;
}

.tableItem:hover td:nth-child(2) {
  color: rgb(57, 57, 182);
}

.modalButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.modalButtons button {
  width: 125px;
  height: 30px;
  margin: 0 20px;
  font-size: 16px;
  border-radius: 25px;
}


@media (max-width: 425px) {
  .title {
    font-size: 1rem;
  }

  .pagination {
    width: calc(100% - 2rem);
  }
}

@media (max-width: 768px) {

  .itemsCount {
    position: static;
    bottom: auto;
    left: auto;
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}