.container {
  max-width: 1100px;
  width: 100%;
  position: relative;
}

.title {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  background: linear-gradient(45deg, rgb(185 26 26) 0%, rgb(253 196 126) 100%);
  padding: 0 16px;
  color: #ffffff;
  font-size: 23px;
  font-weight: 400;
}

.newsList {
  width: 100%;
  min-height: 523px;
  height: calc(100% - 34px);
  padding: 0px 14px 70px;
  background-color: #fff;
  position: relative;
}

.newsListHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px 10px 4px;
}

.tableHeader {
  display: flex;
  align-items: center;
  min-height: 36px;
  border-bottom: 1px solid #c4c4c4;
}



.tableHeader div:first-child {
  color: #4c4c4c;
  font-size: 15px;
  font-weight: 700;
}

.tableRow {
  padding: 3px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d8d5d5;
}

.tableRow:hover {
  background-color: #fcf6f6b4;
}

.displayname {
  color: #5b5c61;
  cursor: pointer;
  flex-basis: 45%;
}

.displayname span {
  position: relative;
  margin-left: 5px;
}

.displayname span::after {
  position: absolute;
  bottom: 5px;
  left: 0;
  content: "";
  height: 1px;
  width: 100%;
  background-color: rgb(75, 112, 109);
  opacity: 0;
  transition: all 0.2s ease-in;
}

.displayname:hover span {
  color: rgb(16, 86, 80);
  font-weight: 500;
}

.displayname:hover span::after {
  opacity: 1;
  transform: translateY(5px);
}

.username {
  flex-basis: 40%;
}

.superuser {
  flex-basis: 10%;
}

.actions {
  flex-basis: 5%;
}

.actions svg {
  color: rgb(204, 20, 20);
  cursor: pointer;
  font-size: 24px;
}

.actions svg:hover {
  color: #ff0000;
}

.deleteIcon {
  width: 20px;
  cursor: pointer;
}

.pagination {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
}

.controls {
  position: absolute;
  bottom: 25px;
  left: 25px;
  display: flex;
  align-items: center;
}

.sortBlock {
  border: 1px solid rgb(187, 184, 184);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.sortBlock:active {
  background-color: rgb(250, 226, 192);
}

.sortActive {
  background-color: rgb(208, 253, 238);
  border-color: rgb(148, 146, 146);
}

.sortBlock svg {
  font-size: 20px;
  color: rgb(187, 184, 184);
}

.sortActive svg {
  color: rgb(148, 146, 146);
}

.sortBlock:hover {
  transform: scale(1.05);
}

/*==============================MODAL===================================*/

.closeBtn {
  display: none;
}

.modal {
  max-width: 700px;
  width: 100%;
  height: auto;
  padding: 0;
  background-color: #fff;
  overflow: hidden;
}

.modalBody {
  width: 100%;
  padding: 15px 15px 30px;
}

.modalMain>div {
  margin-bottom: 8px;
}


.input {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
}

.select {
  margin-top: 10px;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
}

.filesBlock {
  margin-top: 15px;
  width: 100%;
  word-break: break-all;
}

.fileItem {
  display: flex;
  padding-left: 10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px dashed rgb(119, 118, 118);
}

.fileItem:hover {
  border: 1px solid rgb(119, 118, 118);
}

.fileItem a {
  width: 100%;
}

.fileItem+.fileItem {
  margin-top: 5px;
}

.imgBlock {
  margin: 4px 10px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.imgBlock img {
  max-width: 100%;
  max-height: 100%;
}

.addFilesList div {
  display: flex;
  align-items: center;
  color: rgb(110, 109, 109);
}

.addFilesList span:last-child {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.inputBlock {
  margin-top: 10px;
}

.modalButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.modalButtons button {
  width: 125px;
  height: 30px;
  margin: 0 20px;
  font-size: 16px;
  border-radius: 25px;
}

@media (max-width: 425px) {
  .title {
    font-size: 1rem;
  }

  .pagination {
    width: calc(100% - 2rem);
  }
}