
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -99;
  background-color: #00000073;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: .2s;

  > div {
    background: linear-gradient(45deg, rgb(249 217 170), rgb(240 164 107));
    padding: 1rem;

    > h1 {
          color: #656565;
        }
      
    > button {
      color: white;
      background-color: brown;
      font-size: 1rem;
      padding: .5rem 1rem;
      border: none;
      cursor: pointer;
      margin: 2rem auto 0;
      display: block;

      &:hover {
        box-shadow: 0 0 3rem grey;
      }
    }
  }
}

.visible {
  z-index: 999;
  opacity: 1;
}