.container {
  max-width: 1100px;
  width: 100%;
  position: relative;
}

.title {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  background: linear-gradient(45deg, rgb(185 26 26) 0%, rgb(253 196 126) 100%);
  padding: 0 16px;
  color: #ffffff;
  font-size: 23px;
  font-weight: 400;
}

.newsList {
  width: 100%;
  min-height: 523px;
  height: calc(100% - 34px);
  padding: 0px 14px 70px;
  background-color: #fff;
  position: relative;
}

.newsListHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px 10px 4px;
  flex-wrap: wrap;
}

.tableHeader {
  display: flex;
  align-items: center;
  min-height: 36px;
  border-bottom: 1px solid #c4c4c4;
}



.tableHeader div:first-child {
  color: #4c4c4c;
  font-size: 15px;
  font-weight: 700;
}

.tableRow {
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d8d5d5;
}

.tableRow:hover {
  background-color: #fcf6f6b4;
}

.displayname {
  color: #5b5c61;
  cursor: pointer;
  flex-basis: 45%;
}

.displayname span {
  position: relative;
  margin-left: 5px;
}

.displayname span::after {
  position: absolute;
  bottom: 5px;
  left: 0;
  content: "";
  height: 1px;
  width: 100%;
  background-color: rgb(75, 112, 109);
  opacity: 0;
  transition: all 0.2s ease-in;
}

.displayname:hover span {
  color: rgb(16, 86, 80);
  font-weight: 500;
}

.displayname:hover span::after {
  opacity: 1;
  transform: translateY(5px);
}

.username {
  flex-basis: 40%;
}

.superuser {
  flex-basis: 10%;
}

.actions svg {
  color: rgb(204, 20, 20);
  cursor: pointer;
  font-size: 24px;
}

.actions svg:hover {
  color: #ff0000;
}

.deleteIcon {
  width: 20px;
  cursor: pointer;
}

.pagination {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
}

.controls {
  position: absolute;
  bottom: 25px;
  left: 25px;
  display: flex;
  align-items: center;
}

.sortBlock {
  border: 1px solid rgb(187, 184, 184);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.sortBlock:active {
  background-color: rgb(250, 226, 192);
}

.sortActive {
  background-color: rgb(208, 253, 238);
  border-color: rgb(148, 146, 146);
}

.sortBlock svg {
  font-size: 20px;
  color: rgb(187, 184, 184);
}

.sortActive svg {
  color: rgb(148, 146, 146);
}

.sortBlock:hover {
  transform: scale(1.05);
}

/*==============================MODAL===================================*/

.closeBtn {
  display: none;
}

.modal {
  max-width: 900px;
  width: 100%;
  height: auto;
  padding: 0;
  background-color: #fff;
  overflow: hidden;
}

.modalBody {
  width: 100%;
  padding: 15px 15px 30px;
}

.modalMain {}

.input {
  width: 100%;
  height: 24px;
  border: 1px solid #cecece;
  background-color: #ffffff;
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  padding: 3px 6px;
  box-sizing: border-box;
  resize: none;
  width: 150px;
}

.inputMod {
  width: 100%;
  height: 24px;
  border: 1px solid #cecece;
  background-color: #ffffff;
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  padding: 3px 6px;
  box-sizing: border-box;
  resize: none;
}

.inputMod2 {
  width: 100%;
  height: 24px;
  border: 1px solid #cecece;
  background-color: #ffffff;
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  padding: 3px 6px;
  box-sizing: border-box;
  resize: none;
  max-width: calc(100% - 32px);
}

.inputMod3 {
  width: 100%;
  height: 24px;
  border: 1px solid #cecece;
  background-color: #ffffff;
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  padding: 3px 6px;
  box-sizing: border-box;
  resize: none;
  max-width: 300px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mapBlock {
  margin-top: 15px;
  font-size: 16px;
  font-weight: 700;
  color: #6b6b6b;
}

.select {
  width: 90%;
  font-size: 18px;
  font-weight: 500;
}

.filesBlock {
  margin-top: 15px;
  width: 90%;
  word-break: break-all;
}

.fileItem {
  display: flex;
  padding-left: 10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px dashed rgb(119, 118, 118);
}

.fileItem:hover {
  border: 1px solid rgb(119, 118, 118);
}

.fileItem a {
  width: 90%;
}

.modalBody p {
  margin: 0px 0 5px;
}

.fileItem+.fileItem {
  margin-top: 5px;
}

.imgBlock {
  margin: 4px 10px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.imgBlock img {
  max-width: 100%;
  max-height: 100%;
}

.modalButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.modalButtons button {
  width: 125px;
  height: 30px;
  margin: 0 20px;
  font-size: 16px;
  border-radius: 25px;
}

.firstBlock {
  display: flex;
  justify-content: space-between;
}

.firstBlock__left {
  display: flex;
  flex-direction: column;
  width: 65%;
  margin-right: 15px;
}

.imageBlock {
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* max-width: 250px; */
}

.imageBlock select {
  color: #000;
  font-style: normal;
  margin: .5rem 0;
  width: 100%;
}

.imageBlock label {
  width: 100%;
  margin: 1rem auto;
  text-align: center;
}

.imageBlock p {
  margin-top: 1rem;
}

.imageBlock button,
.inputButton {
  margin-top: 3px;
  background-color: rgba(83, 85, 85, 0.082);
  padding: 5px 15px;
  font-size: 13px;
  border: 1px solid rgb(177, 171, 171);
  border-radius: 5px;
  color: rgb(119, 119, 119);
  cursor: pointer;
}

.imageBlock button:hover,
.inputButton:hover {
  background-color: rgba(83, 85, 85, 0.26);
  transition: all 0.2s ease-in;
}

.image {
  border: 1px solid rgb(177, 171, 171);
  height: 180px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.image img {
  max-width: 100%;
  max-height: 100%;
}

.task__label {
  color: #989898;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 5px;
  width: 100%;
}

.eventBlock {
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  top: 70px;
  background-color: #fff;
  border-left: 1px solid #989898;
  border-bottom: 1px solid #989898;
  z-index: 1;
}


.eventBlock::-webkit-scrollbar {
  width: .5rem;
}

.eventBlock::-webkit-scrollbar-track {
  background-color: #eee;
  border-radius: 1rem;
}

.eventBlock::-webkit-scrollbar-thumb {
  background-color: rgb(95, 95, 95);
  border-radius: 1rem;
}

.eventBlockItems {
  font-size: 14px;
  display: block;
  cursor: pointer;
  padding: .3rem;
  border-top: 1px solid;
}

.searchBlock {
  display: flex;
  margin-bottom: .5rem;
  margin-top: .5rem;
  flex-wrap: wrap;
}

.eventBlockItems:hover {
  background-color: #c7c2c2;
}

.searchIcon {
  font-size: 24px;
  width: 24px;
  height: 24px;
  display: block;
  color: #000;
  cursor: pointer;
  margin-left: .5rem;
}

.eventTitle {
  font-size: 14px;
  margin-bottom: 1rem;
}

.docBlock {
  display: flex;
  align-items: center;
  width: 200px;
  font-weight: 500;
}

.docBlock img {
  width: 50%;
}

.docBlock > a div {
  width: 300px;
  word-break: break-all;
}

.iconDelFile {
  font-size: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 1rem;
  cursor: pointer;
}


.docIcon {
  font-size: 3rem;
  width: 3rem;
  height: 3rem;
  color: red;
}

.buttonFile {
  width: fit-content;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.quillWrapper {
  display: block;
  margin-bottom: 1rem;
}

.names {
  font-size: 14px;
  font-weight: 500;
}

.wrapperNames {
  height: 150px;
  overflow-y: auto;
}

.wrapperNames::-webkit-scrollbar {
  width: .5rem;
}

.wrapperNames::-webkit-scrollbar-track {
  background-color: #eee;
  border-radius: 1rem;
}

.wrapperNames::-webkit-scrollbar-thumb {
  background-color: rgb(95, 95, 95);
  border-radius: 1rem;
}

.regionSelect {
  border: 1px solid #cecece;
  margin-right: 1rem;
  max-width: 250px;
  margin-bottom: 1rem;
  height: 1.5rem;
  font-weight: 500;
}

.lvl {
  font-weight: 500;
  color: #d7001b;
}

@media (max-width: 768px) {

  .imageBlock {
    width: 100%;
  }

  .stepTitle {
    min-width: 100px;
    width: 100%;
    margin-left: .5rem;
  }

  .stepText {
    display: block;
    min-width: 100px;
    width: 100%;
  }

  .stepResult {
    min-width: 60px;
    width: 100%;
  }

  .stepWrapper:first-child {
    display: none;
  }

  .stepTitle {
    margin-left: 0;
  }

  .stepWrapper {
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-bottom: 1px solid #989898;
  }

  .firstBlock__left {
    width: 100%;
  }

  .firstBlock {
    flex-direction: column;
  }
}

@media (max-width: 425px) {
  .title {
    font-size: 1rem;
  }

  .pagination {
    width: calc(100% - 2rem);
  }
}