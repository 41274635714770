.container {
    width: 100%;
    min-height: 55vh;
    padding: 2rem 2rem 2rem;
}

.header {    
    background-repeat: no-repeat;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    background-size: cover;
    padding: 1rem;
}

.header h1 {
    margin-top: 15px;
    color: brown;
    font-size: 26px;
    font-weight: 400;
    max-width: 700px;
    text-align: center;
    flex-wrap: wrap;
}

.logo {
    width: 150px;
}

.content {
    width: 100%;
    margin: 0 auto 2rem;

}

.content h2 {
    text-align: center;
    color: rgb(105, 104, 104);
    user-select: none;
    font-size: 1.5rem;
    margin-bottom: .5rem;
}

.content p {
    color: rgb(105, 104, 104);
    line-height: 1.5rem;
}