.container {
  position: relative;
  max-width: 1100px;
  width: 100%;
}

.title {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  background: linear-gradient(45deg, rgb(185 26 26) 0%, rgb(253 196 126) 100%);
  padding: 0 16px;
  color: #ffffff;
  font-size: 23px;
  font-weight: 400;
}

.newsList {
  width: 100%;
  min-height: 523px;
  height: calc(100% - 34px);
  padding: 0px 14px 70px;
  background-color: #fff;
  position: relative;
}

.newsListHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px 10px 4px;
}

.tableHeader {
  display: flex;
  align-items: center;
  min-height: 36px;
  border-bottom: 1px solid #c4c4c4;
}



.tableHeader div:first-child {
  color: #4c4c4c;
  font-size: 15px;
  font-weight: 700;
}

.tableRow {
  padding: 3px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d8d5d5;
}

.tableRow:hover {
  background-color: #fcf6f6b4;
}

.displayname {
  color: #5b5c61;
  cursor: pointer;
  flex-basis: 45%;
}

.displayname span {
  position: relative;
  margin-left: 5px;
}

.displayname span::after {
  position: absolute;
  bottom: 5px;
  left: 0;
  content: "";
  height: 1px;
  width: 100%;
  background-color: rgb(75, 112, 109);
  opacity: 0;
  transition: all 0.2s ease-in;
}

.displayname:hover span {
  color: rgb(16, 86, 80);
  font-weight: 500;
}

.displayname:hover span::after {
  opacity: 1;
  transform: translateY(5px);
}

.username {
  flex-basis: 40%;
}

.superuser {
  flex-basis: 10%;
}

.actions {
  flex-basis: 5%;
}

.actions svg {
  color: rgb(204, 20, 20);
  cursor: pointer;
  font-size: 24px;
}

.actions svg:hover {
  color: #ff0000;
}

.deleteIcon {
  width: 20px;
  cursor: pointer;
}

.pagination {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
}

.controls {
  position: absolute;
  bottom: 25px;
  left: 25px;
  display: flex;
  align-items: center;
}

.sortBlock {
  border: 1px solid rgb(187, 184, 184);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.sortBlock:active {
  background-color: rgb(250, 226, 192);
}

.sortActive {
  background-color: rgb(208, 253, 238);
  border-color: rgb(148, 146, 146);
}

.sortBlock svg {
  font-size: 20px;
  color: rgb(187, 184, 184);
}

.sortActive svg {
  color: rgb(148, 146, 146);
}

.sortBlock:hover {
  transform: scale(1.05);
}

.itemsCount {
  margin-left: 10px;
  user-select: none;
}

.itemsCount select {
  color: grey;
  cursor: pointer;
  outline: none;
  border-radius: 3px;
}

.itemsCount span {
  margin-left: 5px;
  color: grey;
}


.closeBtn {
  display: none;
}

.modal {
  max-width: 580px;
  width: 100%;
  height: auto;
  padding: 0;
  background-color: #fff;
  overflow: hidden;
}

.modalBody {
  width: 100%;
  padding: 15px 15px 30px;
}

/* .fileBlock {
  margin-top: 1rem;
  padding: 1rem;
} */

.fileBlock > p {
  font-size: .9rem;
  font-weight: 500;
  color: rgb(83, 1, 1);
  margin: 7px 0 4px 0;
}


.fileItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease-in;
  width: 100%;
}

.fileItem > svg {
  color: red;
  font-size: 24px;
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  transition: all 0.2s ease-in;
  animation: appearance 0.2s ease-in;
}

.fileItem > svg:first-child {
  font-size: 22px;
  right: 25px;
  color: #203372;
}

.fileItem > svg:hover {
  transform: scale(1.2);
}

.filePreview {
  width: 100%;
  height: 300px;
  border: 1px solid grey;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgb(201, 200, 200);
  transition: all 0.2s ease-in;
}

.filePreview img {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filePreview button {
  position: absolute;
  bottom: 50px;
  left: 35%;
  width: 30%;
  background-color: rgba(128, 128, 128, 0.715);
  color: white;
  border: 1px solid white;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 400;
  transition: all 0.2s ease-in;
  display: none;
  animation: appearance 0.2s ease-in;
}

.filePreview button:hover {
  opacity: 0.8;
}

.fileTitle {
  max-width: 90%;
  margin-top: 5px;
  word-break: break-all;
  text-align: center;
  color: #203372;
  transition: all 0.2s ease-in;
}

.fileItem:hover .filePreview {
  box-shadow: 0 0 10px rgb(116, 115, 115);
  transform: scale(1.005);
}

.fileItem:hover > svg {
  display: block;
  z-index: 1;
}

.fileItem:hover button {
  display: block;
}

.fileItem:hover .fileTitle {
  text-decoration: underline;
}

.imgBlock {
  margin: 4px 10px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.imgBlock img {
  max-width: 100%;
  max-height: 100%;
}

.input {
  margin-top: 10px;
  /* width: 100%;
  font-size: 18px;
  font-weight: 500;
  position: relative; */
}

.modalButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.modalButtons button {
  width: 125px;
  height: 30px;
  margin: 0 20px;
  font-size: 16px;
  border-radius: 25px;
}

@media (max-width: 425px) {
  .title {
    font-size: 1rem;
  }

  .pagination {
    width: calc(100% - 2rem);
  }
}

@keyframes appearance {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@media (max-width: 768px) {

  .itemsCount {
    position: static;
    bottom: auto;
    left: auto;
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}